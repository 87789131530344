import React, {useCallback, useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Box, Checkbox, CheckboxProps, FormControlLabel, withStyles} from "@material-ui/core";
import green from "@material-ui/core/colors/green";

function getDefaultChoicesForm(): string[] {
	return [
		'', '', '', ''
	];
}

export type Props = {
	choices?: string[];
	correctChoiceIndex: number;
	setChoices: (choices: string[]) => unknown;
	setCorrectChoiceIndex: (index: number) => unknown;
}

export const ChoicesArray = (props: Props) => {
	const [choices, setChoices] = useState((props.choices && props.choices.length > 0) ? props.choices : getDefaultChoicesForm());
	const [correctChoiceIndex, setCorrectChoiceIndex] = useState<number>(props.correctChoiceIndex || 0);
	const [allChoices, setAllChoices] = useState(props.choices || getDefaultChoicesForm());
	const propsSetChoices = props.setChoices;
	const setCorrectChoiceCallback = props?.setCorrectChoiceIndex || function(){};
	useEffect(() => {
		const choices = props.choices && props.choices.length > 0 ? props.choices : getDefaultChoicesForm();
		setChoices(choices);
		setAllChoices(allChoices && allChoices.length > 0 ? allChoices : choices);
	}, [allChoices, props.choices]);

	useEffect(() => {
		// purposefully mutate allChoices so this effect does not repeat forever
		const modifiedAllChoices = allChoices;
		(choices || []).forEach((c, i) => modifiedAllChoices[i] = c);
		setAllChoices(modifiedAllChoices || getDefaultChoicesForm());
		propsSetChoices(choices);
	}, [choices, allChoices, propsSetChoices]);

	const setCorrectChoice = useCallback((i: number) => {
		setCorrectChoiceIndex(i);
		setCorrectChoiceCallback(i);
	}, [setCorrectChoiceCallback]);

	const addChoice = useCallback(() => {
		setChoices(choices => [...(choices || []), allChoices[choices.length] || '']);
	}, [allChoices]);

	const removeChoice = useCallback(() => {
		const newChoices = choices.slice(0, choices.length - 1)
		setChoices(newChoices);
		if (correctChoiceIndex >= newChoices.length) {
			setCorrectChoice(correctChoiceIndex-1)
		}
	}, [choices, correctChoiceIndex, setCorrectChoice]);

	return useMemo(() => (
		<form>
			{(choices || []).map((choice, i) =>
				<Box display={"flex"} key={i}>
					<TextField value={choice}
							   multiline
							   inputProps={{style: {fontSize: 13}}}
							   fullWidth={true}
							   variant="outlined"
							   onChange={(e: any) => setChoices([...choices.slice(0, i), e.target.value, ...choices.slice(i + 1, choices.length)])}
					/>
					<GreenCheckbox checked={correctChoiceIndex === i} onChange={() => setCorrectChoice(i)}/>
				</Box>
			)}
			<Button onClick={addChoice}>Add choice</Button>
			<Button onClick={removeChoice}>Remove choice</Button>
		</form>
	), [addChoice, choices, correctChoiceIndex, removeChoice, setCorrectChoice]);
}

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
