import React from 'react';
import {
    Container, Row, Col, FormSelect, FormInput, Button
} from "shards-react";
import {withFirebase} from "../../components/Firebase";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import {checkUserLoggedStatus} from "../../helpers/helper-functions";

export class AdminUpdateLicense extends React.Component {

    updateUserLicense(data) {
        this.props.firebase.db.collection('User')
            .where("email", "==", data.email)
            .get()
            .then(response => {
                let batch = this.props.firebase.db.batch()
                response.docs.forEach((doc) => {
                    const docRef = this.props.firebase.db.collection('User').doc(doc.id)
                    batch.update(docRef, {licenseExpirationDate: data.licenseExpirationDate})
                })
                batch.commit().then(() => {
                    alert("User's license updated!")
                })
            })
    }

    constructor(props) {
        super(props);

        checkUserLoggedStatus()
    }

    render() {

        return (
            <div style={{ height: window.innerHeight, overflowY: 'scroll' }}>
                <Container className="main-content-container px-4">
                    <Col style={{justifyContent: 'center'}}>
                        <Row style={{justifyContent: 'center'}}>
                            <Col md={10} style={{justifyContent: 'center', marginTop: '30px'}}>
                                <Row style={{marginTop: '30px'}}>
                                    <Col md={4}>
                                        <label htmlFor="feInputName">Student's Email</label>
                                        <FormInput id="email" type="email" placeholder='eq: reqmasterco@gmail.com'/>
                                    </Col>
                                    <Col md={4}>
                                        <label htmlFor="feInputName">Student's License Expiration Date</label>
                                        <RangeDatePicker id="date" value/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'center', marginTop: '60px'}}>
                            <Col md={10}>
                                <Button size="md" style={{width: '200px'}} onClick={() => {
                                    let email = document.getElementById('email').value
                                    let date = document.getElementById('endDate').value

                                    let data = {
                                        'email': email,
                                        'licenseExpirationDate': date
                                    }

                                    this.updateUserLicense(data)
                                }}>
                                    Update license
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>

        );
    }
}

export default withFirebase(AdminUpdateLicense);
