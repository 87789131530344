import React, {useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import {StateProps} from '../views/Report';

export type Props = StateProps;

export const ReportRedirect = (props: Props) => {
	return useMemo(() => (
		<Redirect
			to={{
				pathname: "/student/report",
				state: props,
			}}
		/>
	), [props])
}

export default ReportRedirect;
