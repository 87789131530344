// This components controls answering exam questions.
// It handles navigation forward / backward and communicating with the database given a set of categories / exam type and optional timer

import {useCurrentFirestoreUser} from '../firebase-listeners/firestore-user';
import {ExamQuestion, ExamStats, ExamTime, getDefaultExamStats, QuizQuestion} from '../firebase-listeners/interface';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import TopBar from './TopBar';
import AnswerForm from './AnswerForm';
import ShowImage from './ShowImage';
import CorrectAnswerBox from './CorrectAnswerBox';
import QuestionsNavigation from './QuestionsNavigation';
import {setExamStats as saveExamStatsInDB} from '../actions/firestore';
import {randomizeQuestions, uniqueArray} from '../helpers/helper-functions';
import Timer from './Timer';
import ReportRedirect from './ReportRedirect';
import {History} from 'history';
import {PreventCopyPaste} from './PreventCopyPaste';

export type Props = {
	questions: ExamQuestion[];
	examType: 'Simulation' | 'Practice';
	exam: 'CBAP' | 'CCBA' | 'ECBA' | '';
	category: string;
	timer?: ExamTime;
	shouldShowReport: boolean;
	setExamStats?: (examStats: ExamStats) => unknown;
	endButtonCTA: {
		label: string;
		title: string;
		onClick: (examKey: string) => unknown;
	};
	showAnswerCard: boolean;
	topBarTitle: string;
	restartExamUrl?: string;
	history: History;
}

export function makeExamKey(r: { exam: 'CBAP' | 'CCBA' | 'ECBA' | '', examType: 'Simulation' | 'Practice', categories: string[] }): string {
	let s = '';
	if (r.exam) {
		s = r.exam.toLowerCase().replace(/ /g, '-');
	}
	s = `${s}-${r.examType.toLowerCase().replace(/ /g, '-')}`;
	(r.categories || [])
		.map(cat => cat.replace(/ /g, '-').toLowerCase())
		.sort((a: string, b: string) => a.localeCompare(b))
		.forEach(cat => s = `${s}-${cat}`);
	return s;
}

const emptyObject = {};
export const Exam = (props: Props) => {
	const user = useCurrentFirestoreUser();
	const uid = user?.uid;
	const [examKey, setExamKey] = useState<string>(makeExamKey({
		exam: props.exam,
		examType: props.examType,
		categories: [props.category],
	}));
	const [flaggedMode, setFlaggedMode] = useState<boolean>(false);
	const [showReport, setShowReport] = useState<boolean>(false);
	const [examStats, setExamStats] = useState<ExamStats>(user?.examStats[examKey] || {});
	const [atLastQuestion, setAtLastQuestion] = useState<boolean>(false);
	const currentQuestionId = examStats?.currentQuestionId;
	const [spinnerSuccess, setSpinnerSuccess] = useState<any>(undefined);
	const [answer, setAnswer] = useState<string>(examStats?.answers?.[currentQuestionId] || '');
	const [showAnswerCard, setShowAnswerCard] = useState<boolean>(false);
	const markedQuestionIds = examStats?.markedQuestionIds;
	const answers = examStats?.answers || emptyObject;
	const currentQuestion = props.questions.find(q => q.id === currentQuestionId);
	const currentQuestionNumber = Math.max((examStats?.currentQuestionOrder?.indexOf(currentQuestion?.id || '') || 0), 0);
	useEffect(() => {
		const stats = user?.examStats[examKey] || {};
		const conditions = [
			currentQuestion && stats?.currentQuestionId && stats?.currentQuestionOrder,
			!props.questions || props.questions.length === 0,
			!user,
			!uid,
			!examKey,
		];
		const everythingOk = conditions.some(Boolean);
		if (everythingOk) {
			return;
		}
		const examStats = getDefaultExamStats();
		const questionOrder = randomizeQuestions(props.questions);
		examStats.currentQuestionOrder = uniqueArray(questionOrder.map(q => q.id));
		examStats.currentQuestionId = questionOrder[0].id;
		user.examStats = {
			...(user.examStats || {}),
			[examKey]: examStats,
		};
		setExamStats(examStats);
		saveExamStatsInDB({examKey, examStats, uid})
	}, [currentQuestion, examKey, props.questions, user, uid])

	useEffect(() => {
		setExamKey(makeExamKey({exam: props.exam, examType: props.examType, categories: [props.category]}));
	}, [props.exam, props.examType, props.category]);

	useEffect(() => {
		setExamStats(user?.examStats[examKey] || {});
	}, [user, examKey]);

	useEffect(() => {
		setAnswer(answers[currentQuestionId] || '');
	}, [currentQuestionId, answers]);

	useEffect(() => {
		if (props?.setExamStats) {
			props.setExamStats(examStats);
		}
	}, [examStats, props]);

	const isCurrentQuestionFlagged = useCallback(() => {
		if (!markedQuestionIds) {
			return false;
		}
		return markedQuestionIds.indexOf(currentQuestionId) >= 0;
	}, [markedQuestionIds, currentQuestionId]);

	const flagQuestion = useCallback(() => {
		const newStats = {...examStats, markedQuestionIds: [...(examStats.markedQuestionIds || []), currentQuestionId]};
		setExamStats(newStats);
		saveExamStatsInDB({uid: uid, examKey, examStats: newStats});
	}, [uid, examKey, examStats, currentQuestionId]);

	const unFlagQuestion = useCallback(() => {
		const newStats = {
			...examStats,
			markedQuestionIds: (examStats.markedQuestionIds || []).filter(qId => qId !== currentQuestionId)
		};
		setExamStats(newStats);
		saveExamStatsInDB({uid: uid, examKey, examStats: newStats});
	}, [uid, examKey, examStats, currentQuestionId]);

	const saveAnswer = useCallback((answer: string) => {
		const newStats = {...examStats, answers: {...examStats.answers, [currentQuestionId]: answer}};
		setExamStats(newStats);
		saveExamStatsInDB({uid, examKey, examStats: newStats});
	}, [currentQuestionId, examKey, examStats, uid]);

	const showCorrectAnswer = useCallback(() => {
		setShowAnswerCard(!showAnswerCard);
	}, [showAnswerCard]);

	const navigateToQuestion = useCallback((questionNumber: number) => {
		setShowAnswerCard(false);
		// Skip over questions that may no longer exist.
		let question = undefined;
		let nextQuestionId: string = '';
		let currentQuestionOrder = uniqueArray(examStats.currentQuestionOrder);
		while (!question) {
			nextQuestionId = currentQuestionOrder[questionNumber];
			question = props.questions.find(q => q.id === nextQuestionId)
			if (!question) {
				currentQuestionOrder.splice(questionNumber, 1);
			}
		}
		
		const newStats: ExamStats = {
			...examStats,
			currentQuestionId: nextQuestionId,
			currentQuestionOrder,
		};
		setExamStats(newStats);
		setAtLastQuestion(currentQuestionNumber === props?.questions?.length-1);
		saveExamStatsInDB({uid, examKey, examStats: newStats});
	}, [currentQuestionNumber, examKey, examStats, props, uid]);

	const getEnunciationSections = useCallback(() => {
		// Returns a list of strings used to separate the text in a question
		return currentQuestion?.enunciation?.split('\n') || [''];
	}, [currentQuestion]);

	const Report = useMemo(() => (
		<ReportRedirect topBarTitle={props.topBarTitle} restartExamUrl={props?.restartExamUrl} exam={props?.exam} examKey={examKey} examStats={examStats} questions={props?.questions} uid={uid} category={props?.category}/>
	), [examKey, examStats, props, uid]);

	const LoadingQuestions = useMemo(() => {
		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "75%"}}>
				<p>Loading questions...</p>
			</div>
		)
	}, []);

	const Questions = useMemo(() => {
		return (
			<div className="container">
				<TopBar history={props.history} title={props.topBarTitle}/>

				<div className="mt-3" style={{top: "20px"}}>
					<button
						data-toggle="tooltip"
						data-placement="top"
						title={props.endButtonCTA.title}
						type="button"
						className="btn btn-link"
						style={{
							float: "right",
							marginRight: "10px",
							fontSize: "18px",
							cursor: "pointer",
						}}
						onClick={(e) => {
							e.preventDefault();
							props.endButtonCTA.onClick(examKey);
						}}
					>
						{props.endButtonCTA.label}
					</button>
					<p style={{display: "inline", float: "right"}}>
						{spinnerSuccess ? spinnerSuccess : <></>}
					</p>
				</div>
				{props?.timer?.timerActive && (
					<div className="row">
						<div className="col"/>
						<div className="col">
							<Timer
								hours={props.timer.hours}
								minutes={props.timer.minutes}
								seconds={props.timer.seconds}
								uid={uid}
								examKey={examKey}
							/>
						</div>
						<div className="col"/>
					</div>
				)}
				<div
					style={{
						border: "1px solid silver",
						marginTop: "70px",
						borderRadius: "10px",
					}}
					className="question-card row pb-5"
				>
					<div
						style={{height: "max-content"}}
						className="col-2 mt-4 ml-4 mb-4 exam-questionNo-box"
					>
						<h4
							className="mt-2"
							style={{fontSize: "22px", fontWeight: "normal"}}
						>
							{currentQuestionNumber + 1 + "/" + props.questions.length}
						</h4>
						{isCurrentQuestionFlagged() ? (
							<a
								onClick={(e) => unFlagQuestion()}
								role="button"
								href="javascript:void(0);"
							>
								<img
									src={require("../assets/images/flag-question.png")}
									style={{height: "18px", marginRight: "7px"}}
								/>
								Unmark Question
							</a>
						) : (
							<a
								onClick={(e) => flagQuestion()}
								role="button"
								href="javascript:void(0);"
							>
								<img
									src={require("../assets/images/flag-question.png")}
									style={{height: "18px", marginRight: "7px"}}
								/>
								Mark Question
							</a>
						)}
						<p></p>
					</div>
					<div className="col mt-4">
						<PreventCopyPaste>
							{
								getEnunciationSections().map(enunciation => {
									return <p key={enunciation} style={{fontSize: "18px"}}>
										{enunciation.replace(
											/%missing_word%/g,
											"_________"
										)}
									</p>
								})
							}
						</PreventCopyPaste>
						<p></p>
						{currentQuestion ? <AnswerForm
							onAnswerChanged={saveAnswer}
							currentQuestion={currentQuestion}
							answer={answer}
						/> : <></>}
						<ShowImage src={currentQuestion?.picture || ''}/>
						{props.showAnswerCard && (
							<button type="button"
									className="mt-2 btn btn-primary"
									style={{backgroundColor: "rgb(138, 208, 209)", borderColor: "rgb(0, 142, 143)"}}
									onClick={showCorrectAnswer}
							>
								Show Answer
							</button>
						)}
						{showAnswerCard && props.showAnswerCard ? (
							<CorrectAnswerBox
								showYourAnswer={false}
								selectedAnswer={answer || examStats?.answers?.[currentQuestionId]}
								answer={currentQuestion?.choicesArray?.[currentQuestion?.correctChoiceIndex]}
								explanation={currentQuestion?.explanation}
							/>
						) : null}
						<QuestionsNavigation
							setCurrentQuestion={navigateToQuestion}
							currentQuestionNumber={currentQuestionNumber}
							totalQuestions={props?.questions?.length || 0}
							isFromShowSimulationQuestions={props.examType === 'Simulation'}
							isFromShowQuestions={props.examType === 'Practice'}
							currentQuestionId={currentQuestionId}
							showReport={() => setShowReport(true)}
						/>
					</div>
				</div>
			</div>
		)
	}, [props, currentQuestion, spinnerSuccess, uid, examKey, currentQuestionNumber, isCurrentQuestionFlagged, getEnunciationSections, saveAnswer, answer, showCorrectAnswer, showAnswerCard, examStats, currentQuestionId, navigateToQuestion, unFlagQuestion, flagQuestion]);

	if (showReport) {
		return Report;
	}
	if (!props?.questions?.length) {
		return LoadingQuestions;
	}
	return Questions;
}
