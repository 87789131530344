import React, { } from 'react';
import {setUserExamTime} from "../actions/firestore";


export default function Timer({ background = 'false', hours = 0, minutes = 0, seconds = 0, examKey, uid }) {
    const [paused, setPaused] = React.useState(false);
    const [over, setOver] = React.useState(false);
    const [time, setTime] = React.useState({
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds)
    });
    const [savedTime, setSavedTime] = React.useState(0);
    const [backgroundTime, setBackgroundTime] = React.useState({
        hours: parseInt('96'),
        minutes: parseInt('00'),
        seconds: parseInt('00')
    });
    const tick = () => {
        if (savedTime == 60) {
            setSavedTime(0)
            setUserExamTime({uid, examKey, examTime: time})
        } else {
            setSavedTime(savedTime + 1)
        }

        if (paused) return;

        // Background Timer
        if (backgroundTime.minutes == 0 && backgroundTime.seconds == 0)
            setBackgroundTime({
                hours: backgroundTime.hours - 1,
                minutes: 59,
                seconds: 59
            });
        else if (backgroundTime.seconds == 0)
            setBackgroundTime({
                hours: backgroundTime.hours,
                minutes: backgroundTime.minutes - 1,
                seconds: 59
            });
        else
            setBackgroundTime({
                hours: backgroundTime.hours,
                minutes: backgroundTime.minutes,
                seconds: backgroundTime.seconds - 1
            });

        if (over) return;

        if (time.hours == 0 && time.minutes == 0 && time.seconds == 0) {
            setOver(true);
            alert("The time of the exam is over. The exam will not end")
        }

        else if (time.minutes == 0 && time.seconds == 0)
            setTime({
                hours: time.hours - 1,
                minutes: 59,
                seconds: 59
            });
        else if (time.seconds == 0)
            setTime({
                hours: time.hours,
                minutes: time.minutes - 1,
                seconds: 59
            });
        else
            setTime({
                hours: time.hours,
                minutes: time.minutes,
                seconds: time.seconds - 1
            });


    };

    const reset = () => {
        setTime({
            hours: parseInt(hours),
            minutes: parseInt(minutes),
            seconds: parseInt(seconds)
        });

        setBackgroundTime({
            hours: parseInt('96'),
            minutes: parseInt('00'),
            seconds: parseInt('00')
        });
        setPaused(false);
        setOver(false);
    };

    React.useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    return (
        <div style={{ marginTop: '22px', marginLeft: '35%' }}>

            {
                background === 'true' ?
                    <p style={{ fontSize: '0.5px', marginBottom: '10px' }}>Time left <b>{`${time.hours.toString().padStart(2, '0')}:${time.minutes
                        .toString()
                        .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</b></p>
                    :
                    <p style={{ fontSize: '15px', marginBottom: '10px' }}>Time left <b>{`${time.hours.toString().padStart(2, '0')}:${time.minutes
                        .toString()
                        .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</b></p>
            }
            <div>{over ? '' : ''}</div>
            {/*{*/}
            {/*    background === 'true' ?*/}
            {/*        <></>*/}
            {/*        :*/}
            {/*        <img style={{width: '70px', cursor: 'pointer', marginRight: '5px', marginLeft: '-8px'}} src={require('../assets/images/reset_button.png')} onClick={() => reset()}/>*/}
            {/*}*/}
            {
                background !== 'true' ?
                    paused ?
                        <img style={{ width: '60px', cursor: 'pointer', marginLeft: '30px' }} src={require('../assets/images/play_button.png')} onClick={() => setPaused(!paused)} />
                        :
                        <img style={{ width: '70px', cursor: 'pointer', marginLeft: '30px' }} src={require('../assets/images/pause_button.png')} onClick={() => setPaused(!paused)} />
                    :
                    <></>
            }

        </div>
    );
}
