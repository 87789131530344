import React from "react";
// @ts-ignore
import {FormInput, InputGroup} from "shards-react";
import {ExamQuestion, QuizQuestion} from '../firebase-listeners/interface';
import {noCopyPasteProps} from "./PreventCopyPaste";

export type Props = {
  currentQuestion: ExamQuestion | QuizQuestion;
  answer: string;
  onAnswerChanged: (answer: string) => unknown;
}

export const AnswerForm = (props: Props) => {
  const answerForm = (
      <form>
        {props.currentQuestion.choicesArray.length === 1 &&
        props.currentQuestion.choicesArray[0] === "" ? (
            <InputGroup style={{ width: "30%", marginBottom: "20px" }}>
              <FormInput
                  style={{ fontSize: "15px" }}
                  id="missing_word"
                  placeholder="Type the missing word here"
              />
            </InputGroup>
        ) : (
            props.currentQuestion.choicesArray.map((answerValue, i) => (
                <div key={i}>
                  <input
                      type="radio"
                      name="site_name"
                      style={{ cursor: "pointer" }}
                      value={answerValue}
                      checked={
                        props.answer ? props.answer === answerValue : false
                      }
                      onChange={() =>
                          props.onAnswerChanged(answerValue)
                      }
                  />
                  <label
                      {...noCopyPasteProps}
                      onClick={() =>
                          props.onAnswerChanged(answerValue)
                      }
                      style={{...noCopyPasteProps.style, display: "inline", cursor: "pointer", fontSize: "18px" }}
                      htmlFor={answerValue}
                  >
                    {" " + answerValue}
                  </label>
                </div>
            ))
        )}
      </form>
  );
  return props.currentQuestion ? answerForm : <></>;
}
export default AnswerForm;
