import {FirestoreUser, getDefaultExamStats} from './interface';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {firestore} from 'firebase/app';
import {useCurrentUserId, useIsUserLoggedIn} from './auth';

// Globally store Exam Questions to avoid excessive firestore calls
let listeners: Dispatch<SetStateAction<FirestoreUser>>[] = [];
let firestoreUser: FirestoreUser = {
	uid: '',
	answerValues: undefined,
	answeredQuestions: undefined,
	availableExam: '',
	email: '',
	examTime: {hours: 0, minutes: 0, seconds: 0},
	firstname: '',
	flaggedQuestions: undefined,
	lastname: '',
	licenseExpirationDate: '',
	practiceAnsweredQuestions: [],
	simulationAnsweredQuestions: [],
	examStats: {},
};
let cancelSubscription: (() => unknown) | undefined = undefined;


const setFirestoreUser = (newState: FirestoreUser) => {
	firestoreUser = newState;
	listeners.forEach((listener) => {
		listener(firestoreUser);
	});
};

export function useCurrentFirestoreUser(): FirestoreUser {
	const isLoggedIn = useIsUserLoggedIn();
	const uid = useCurrentUserId();
	const newListener = useState(firestoreUser)[1];

	useEffect(() => {
		listeners.push(newListener);
	}, [newListener]);

	useEffect(() => {
		// Already listening
		if (isLoggedIn && cancelSubscription) {
			return;
		}
		// Logged out, and is not listening
		if (!isLoggedIn && !cancelSubscription) {
			return;
		}
		// Logged in, not listening yet
		if (isLoggedIn && uid && !cancelSubscription) {
			cancelSubscription = firestore()
				.collection('User')
				.doc(uid)
				.onSnapshot({
					next: (doc: firestore.DocumentSnapshot) => {
						const u = doc.data() as FirestoreUser;
						setFirestoreUser({...u, uid: u.uid || uid, examStats: u.examStats || getDefaultExamStats()});
					},
					error: (error: firestore.FirestoreError) => {
						console.error(`got firestore error reading exam firestoreUser: ${error}`);
					}
				})
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			// only unsubscribe if we are logged out. Keeping the subscription alive does not have bad consequences
			if (!isLoggedIn && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
			}
		}
	}, [isLoggedIn, newListener, uid]);
	return firestoreUser;
}
