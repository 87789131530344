import app from "firebase/app";
import React from "react";
import { clearQusetionsSessionStorage } from "../../helpers/helper-functions";

const { uuid } = require("uuidv4");



class Firebase {
  constructor() {
    this.auth = app.auth();
    this.db = app.firestore();
    this.auth.onAuthStateChanged(obs => sessionStorage.setItem('userId', obs.uid));
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password, data) =>
    this.auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
      this.db
        .collection("User")
        .doc(this.auth.currentUser.uid)
        .set({...data, uid: userCredential?.user?.uid || ''})
        .then((res) => {
          sessionStorage.setItem("userLoggedIn", "true");
          window.location.href = `/student/home?firstname=${data.firstname}`;
        });
    });

  doSignOut = () =>
    this.auth.signOut().then(() => {
      sessionStorage.setItem("userId", "");
      sessionStorage.setItem("userFirstName", "");
      clearQusetionsSessionStorage();
    });

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // *** DB Interrogation API ***

  // QUIZ Questions

  doGetQuizQuestionsByCategory = (categoryName) =>
    this.db
      .collection("Quiz Questions")
      .where("category", "==", categoryName)
      .get()
      .then(function (querySnapshot) {
        let questions = [];
        querySnapshot.forEach(function (doc) {
          questions.push(doc.data());
        });

        return questions;
      });

  doGetQuizQuestionsByAllCategories = () =>
    this.db
      .collection("Quiz Questions")
      .get()
      .then(function (querySnapshot) {
        let questions = [];
        querySnapshot.forEach(function (doc) {
          questions.push(doc.data());
        });
        console.log(questions);
        return questions;
      });

  doPostQuizQuestionsOnCategory = (category, data) =>
    this.db
      .collection("Quiz Questions")
      .doc(uuid())
      .set({
        ...data,
        id: uuid(),
      });

  // EXAM Questions

  doGetExamQuestionsByCategory = (categories) =>
    this.db
      .collection("Exam Questions")
      .get()
      .then(function (querySnapshot) {
        let data = [];
        querySnapshot.forEach(function (doc) {
          if (
            Object.values(categories).indexOf(doc.data().category) > -1 &&
            doc.data().questionType === "Practice"
          ) {
            data.push(doc.data());
          }
        });

        return data;
      });

  getQuizCategories() {
      return this.db
          .collection("Quiz Category")
          .doc("Sequence")
          .get()
          .then(function(doc) {
              if(doc.exists) {
                  console.log(doc.data());
                  const categories = {
                      knowledgeAreas: [...doc.data()["Knowledge Areas"]],
                      techniques: [...doc.data().Techniques],
                  };
                  return categories;
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                  return null;
              }
          })
          .catch(function(error) {
              console.log("Error getting document:", error);
          });
  }

  async addPracticeAnsweredQuestions(questionId) {
    console.log("this is the id", questionId);
    const userId = await this.getUserId();
    return this.db
      .collection("User")
      .doc(userId)
      .update({
        practiceAnsweredQuestions: app.firestore.FieldValue.arrayUnion(
          questionId
        ),
      })
      .then(function () {
        // console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  async getAnsweredQuestionsIds() {
      const userId = await this.getUserId();
      return this.db
          .collection("User")
          .doc(userId)
          .get()
          .then(function(doc) {
              if(doc.exists) {
                  return doc.data().practiceAnsweredQuestions;
              } else {
                  // doc.data() will be undefined in this case
                  // console.log("No such document!");
              }
          })
          .catch(function(error) {
              console.log("Error getting document:", error);
          });
  }

  async getUserId() {
      const uid = sessionStorage.getItem('userId') || this.auth.currentUser?.uid;
      if (uid) {
          return uid;
      }
      return new Promise((resolve) => {
          setTimeout(() => resolve(this.auth.currentUser?.uid), 100);
      });
  }
}

export default Firebase;
