import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

export type CardFlipProps = {
	flipped?: boolean;
	children: React.ReactNode[];
	styles?: CardFlipStyleProps;
	onClick: (event: React.MouseEvent<unknown, unknown>) => unknown;
}

export type CardFlipStyleProps = {
	maxHeight?: string;
	minHeight?: string;
	height?: string;
	width?: string;
	maxWidth?: string;
	cursor?: string;
}

const commonFrontBack = {
	boxSizing: 'border-box',
	border: '1px solid silver',
	height: '100%',
	width: '100%',
	display: 'block',
	padding: '10px',
	position: 'absolute',
	borderRadius: '10px',
	backfaceVisibility: 'hidden',
	transformStyle: 'preserve-3d',
	transition: 'transform ease 500ms',
} as const;

const useStyles = makeStyles<Theme, Partial<CardFlipStyleProps>>({
	cardContainer: (styles: CardFlipStyleProps) => ({
		padding: 0,
		width: styles?.width || 'initial',
		maxWidth: styles?.maxWidth || 'initial',
		height: styles?.height || 'initial',
		minHeight: styles?.minHeight || 'initial',
		maxHeight: styles?.maxHeight || 'initial',
		position: 'relative',
		transformStyle: 'preserve-3d',
		boxSizing: 'border-box',
		cursor: styles.cursor || 'initial',
		'&.flipped': {
			front: {
				transform: 'rotateY(180deg)',
			},
			back: {
				transform: 'rotateY(0)',
			},
		},
	}),

	front: {
		...commonFrontBack,
		zIndex: 2,
		transform: 'rotateY(0deg)',
		/* front tile styles go here! */
	},

	back: {
		...commonFrontBack,
		transform: 'rotateY(-180deg)',
		padding: '20px',
		/* back tile styles go here! */
	},

	frontFlipped: {
		transform: 'rotateY(180deg)',
	},

	backFlipped: {
		transform: 'rotateY(0)',
	},
})

export const CardFlip = (props: CardFlipProps) => {
	const classes = useStyles(props.styles || {});
	return <div className={classes.cardContainer} onClick={props.onClick}>
		<div className={`${classes.front} ${props.flipped ? classes.frontFlipped : ""}`}>
			{props.children[0]}
			{props.children?.length > 2 && props.children.slice(1)}
		</div>
		<div className={`${classes.back} ${props.flipped ? classes.backFlipped : ""}`}>
			{props.children[1]}
			{props.children?.length > 2 && props.children.slice(1)}
		</div>
	</div>
}
