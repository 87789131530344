import React, {useEffect, useState} from "react";
import { withFirebase } from "../../components/Firebase";
import "./AdminExamQuestions.css";
import HomepageButton from "../../components/Buttons/HomepageButton";
// @ts-ignore
import {FormSelect, FormInput, Button} from "shards-react";
import {
  typeArray,
} from "../../helpers/categoryExamTypes";
// @ts-ignore
import { Link } from "react-router-dom";
import {ExamQuestion} from '../../firebase-listeners/interface';
import {deleteExamQuestion} from '../../actions/firestore';
import {useExamTypes} from '../../firebase-listeners/exams';
import {useSequenceCategories} from '../../firebase-listeners/sequence-categories';
import {Typography} from "@material-ui/core";

export type Props = {
    questions: ExamQuestion[];
}

type FilterOptions = {
    category: string;
    exam: string;
    type: string;
    search: string;
}

export const AdminExamQuestionsTable = (props: Props) => {
    const examTypeArray = useExamTypes();
    const questionCategoryArray = useSequenceCategories();
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({
        category: '',
        exam: '',
        type: '',
        search: '',
    });

    const [sortBy, setSortBy] = useState('');
    const [pageQuestions, setPageQuestions] = useState<ExamQuestion[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage,] = useState(20);
    const [totalFilteredQuestions, setTotalFilteredQuestions] = useState<number>(props.questions?.length || 0);

    useEffect(() => {
        const btn_next = document.getElementById("btn_next") as HTMLElement;
        const btn_prev = document.getElementById("btn_prev") as HTMLElement;
        const page_span = document.getElementById("page") as HTMLElement;

        const questions = filterAndSortQuestions(props.questions, filterOptions, sortBy);
        setTotalFilteredQuestions(questions.length);
        const indexOfLastPost = currentPage * recordsPerPage;
        const indexOfFirstPost = indexOfLastPost - recordsPerPage;
        const currentPosts = questions.slice(indexOfFirstPost, indexOfLastPost);
        setPageQuestions([...currentPosts]);

        // Validate page
        if (currentPage > numPages()) setCurrentPage(numPages() || 1)
        if (currentPage < 1) setCurrentPage(1);

        page_span.innerHTML = currentPage + "/" + numPages();

        if (currentPage === 1) {
            btn_prev.style.visibility = "hidden";
        } else {
            btn_prev.style.visibility = "visible";
        }

        if (currentPage === numPages()) {
            btn_next.style.visibility = "hidden";
        } else {
            btn_next.style.visibility = "visible";
        }
    }, [props.questions, filterOptions, sortBy, recordsPerPage, currentPage]);

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage-1);
        }
    };

    const nextPage = () => {
        if (currentPage < numPages()) {
            setCurrentPage(currentPage+1);
        }
    };

    const setFilter = (filterType: string, value: string) => {
        // These three values are actually resetting the filter
        if(value === 'Category' || value === 'Exam' || value === 'Type') {
            value = '';
        }
        setFilterOptions({
            ...filterOptions,
            [filterType]: value,
        });
        setCurrentPage(1);
    }

    const setSearch = (search: string) => {
        setFilterOptions({
            ...filterOptions,
            search: search,
        });
    };

    const numPages = () => {
        const questions = filterAndSortQuestions(props.questions, filterOptions, sortBy);
        return Math.max(Math.ceil(questions.length / recordsPerPage), 1);
    };

    return (
        <div style={{ background: "white" }}>
            <p style={{ display: "inline" }}>Search: </p>
            <FormInput
                style={{ display: "inline", maxWidth: "250px" }}
                placeholder="Search by question id, question, category, exam, or enunciation"
                onChange={(e: any) => setSearch(e.target.value)}
            />
            <p style={{ marginLeft: "10px", display: "inline" }}>Sort by: </p>
            <FormSelect
                onChange={(e: any) => {
                    setSortBy(e.target.value);
                }}
                id="sort"
            >
                <option>Select</option>
                <option>Question</option>
                <option>ID</option>
            </FormSelect>
            <br />
            <p style={{ display: "inline" }}>Filter by: </p>
            <FormSelect
                onChange={(e: any) => setFilter("category", e.target.value)}
                id="category"
            >
                <option>Category</option>
                {questionCategoryArray.map((type, index) => (
                    <option key={index}>{type}</option>
                ))}
            </FormSelect>
            <FormSelect
                onChange={(e: any) => setFilter("exam", e.target.value)}
                id="exam-type"
            >
                <option>Exam</option>
                {examTypeArray.map((type, index) => (
                    <option key={index}>{type}</option>
                ))}
            </FormSelect>
            <FormSelect
                style={{ maxWidth: "150px" }}
                onChange={(e: any) => setFilter("type", e.target.value)}
                id="type"
            >
                <option>Type</option>
                {typeArray.map((type, index) => (
                    <option key={index}>{type}</option>
                ))}
            </FormSelect>
            <br />
            <br />
            <Typography>Total: {totalFilteredQuestions}</Typography>
            <HomepageButton
                message={"Add Exam Questions"}
                link={"/student/add-exam-question"}
            />
            {pageQuestions.map((v, i) => {
                let title = v.enunciation || '';
                let firstSentence = title.match(/[^\.!\?]+[\.!\?]+/g);
                if (firstSentence === null) {
                    firstSentence = [title];
                }
                firstSentence = firstSentence[0] as any;
                let userDefinedId = v.userDefinedId ? v.userDefinedId : "";

                return (
                    <div key={i} className="admin-exam-questions">
                        <p className="admin-exam-question">Question: </p>
                        <p className="inline">{firstSentence}</p>
                        <br />
                        <p className="admin-exam-question">Category: </p>
                        <p className="inline">{v.category}</p>
                        <br />
                        <p className="admin-exam-question">Exam: </p>
                        <p className="inline">{v.exam}</p>
                        <br />
                        <p className="admin-exam-question">Id: </p>
                        <p className="inline">{userDefinedId}</p>
                        <br />
                        <Button
                            onClick={() => {
                                deleteExamQuestion(v.id).catch(function (error) {
                                    console.error("Error removing document: ", error);
                                });
                            }}
                            className="delete-question"
                        >
                            Delete
                        </Button>
                        <span style={{ display: "inline" }}> </span>
                        <Link
                            to={{ pathname: "edit-exam-question/" + v.id }}
                        >
                            Edit
                        </Link>
                    </div>
                );
            })}
            <br />
            <br />
            <a
                style={{ color: "blue" }}
                onClick={() => prevPage()}
                className="nav-btn-pagination"
                id="btn_prev"
            >
                Prev{" "}
            </a>
            <a
                style={{ color: "blue" }}
                onClick={() => nextPage()}
                className="nav-btn-pagination"
                id="btn_next"
            >
                Next{" "}
            </a>
            page: <span id="page"></span>
        </div>
    );
}

export function filterAndSortQuestions(questions: ExamQuestion[], filters: FilterOptions, sortBy: string) {
    let filteredQuestions = questions.filter(q => {
        // Every filter must match by either being unset or equal
        return [
            !filters.category || q.category === filters.category,
            !filters.exam || q.exam === filters.exam,
            !filters.type || q.questionType === filters.type,
            searchMatchesQuestion(filters.search, q)
        ].every(Boolean);
    });

    if (!sortBy || sortBy.toLowerCase() === 'select') {
    	return filteredQuestions;
	}
    if (sortBy.toLowerCase() === 'id' || sortBy.toLowerCase().replace(/ /g, '') === 'userdefinedid') {
        return filteredQuestions.sort((a: ExamQuestion,b:ExamQuestion) => (a.userDefinedId || '').localeCompare((b.userDefinedId || ''), 'en', { sensitivity: 'base' }))
	}
    if (sortBy.toLowerCase() === 'question') {
        return filteredQuestions.sort((a: ExamQuestion,b: ExamQuestion) => a.enunciation.toLowerCase().localeCompare(b.enunciation.toLowerCase(), 'en', { sensitivity: 'base' }))
    }
    return filteredQuestions;
}

function searchMatchesQuestion(search: string, question: ExamQuestion) {
    if (!search) {
        return true;
    }
    const lowerCaseSearch = search.toLowerCase();
    const {enunciation, userDefinedId, exam, category} = question;

    // There must be at least 1 match
    return [
        (userDefinedId || '').toLowerCase().indexOf(lowerCaseSearch) >= 0,
        (exam || '').toLowerCase().indexOf(lowerCaseSearch) >= 0,
        (category || '').toLowerCase().indexOf(lowerCaseSearch) >= 0,
        (enunciation || '').toLowerCase().indexOf(lowerCaseSearch) >= 0,
    ].some(Boolean);
}

export default withFirebase(AdminExamQuestionsTable);

