import React from 'react';

export default class CategoryChoiceBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {

        let message = this.props.message
        let boxSize = window.innerWidth / 4 + 130
        let isChecked = this.props.checked

        return (
            <div>
                {
                    isChecked === true ?
                        <p style={{
                            cursor: 'pointer',
                            borderRadius: '15px',
                            fontSize: '20px',
                            border: '2px solid #42B1B2',
                            background: 'rgba(138,208,209,0.33)',
                            paddingTop: '5px',
                            width: "55vw",
                            height: '45px',
                            color: '#192438',
                            textAlign: 'center',
                            marginBottom: "10px"
                        }}>{message}</p>
                        :
                        <p style={{
                            cursor: 'pointer',
                            borderRadius: '15px',
                            fontSize: '20px',
                            background: '#F4F6F8',
                            paddingTop: '5px',
                            width: "55vw",
                            height: '45px',
                            color: '#192438',
                            textAlign: 'center',
                            marginBottom: "10px"
                        }}>{message}</p>
                }

            </div>
        )
    }
}
