import React, { useState, useEffect } from "react";

const ShowImage = (props) => {
  const [image, setImage] = useState("");
  //var image = null;

  useEffect(() => {
    if (props.src != "NAAN" && props.src) {
      setImage(props.src);
    } else {
      setImage("");
    }
  }, [props]);

  return (
    <div id="image">{image && image !== "" && <img src={image}></img>}</div>
  );
};

export default ShowImage;
