import React, {useMemo, useState} from "react";
// @ts-ignore
import {SolarSystemLoading} from "react-loadingg";
import "bootstrap";
import {Link} from "react-router-dom";
import CategoryChoiceBox from "../components/Selections/CategoryChoiceBox";
import {useSequenceCategories} from '../firebase-listeners/sequence-categories';
import {History} from 'history';
import {doSignOut} from '../actions/auth';
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {ExamQuestion} from '../firebase-listeners/interface';
import Box from '@material-ui/core/Box';

export type Props = {
	history: History;
}

const emptyArray: any[] = []
export const PracticeQuestionCategories = (props: Props) => {
	const categories = useSequenceCategories() || emptyArray;
	const questions = useExamQuestions();

	const [selectedCategory, setSelectedCategory] = useState<string>('');
	const pageLoaded = categories && categories.length > 0 && questions && questions.length > 0;

	const categoryToQuestionsMap: { [category: string]: ExamQuestion[] } = useMemo(() => {
		return (questions || []).reduce((categoryToQuestionMap: { [category: string]: ExamQuestion[] }, question) => {
			return {
				...categoryToQuestionMap,
				[question.category]: [...(categoryToQuestionMap[question.category] || []), question],
			}
		}, {});
	}, [questions]);

	const Loading = useMemo(() => (
		<SolarSystemLoading
			style={{position: "fixed", top: "50%", left: "50%"}}
		/>
	), []);

	const NextArrow = useMemo(() => (
		<div className="row">
			<p style={{color: "#192438", fontSize: "20px", marginRight: 20}}>
				Next
			</p>
			<img
				src={require("../assets/images/right-arrow.png")}
				style={{height: "20px", marginTop: 5}}
			/>
		</div>
	), []);

	const CategoriesSelection = useMemo(() => {
		return <>
			{categories.map(category => (
				<div key={category} className="row" style={{justifyContent: "center"}}>
					<div
						onClick={() => setSelectedCategory(category)}
					>
						<CategoryChoiceBox
							message={category}
							checked={selectedCategory === category}
						/>
					</div>
				</div>
			))}
		</>
	}, [categories, selectedCategory]);

	const PageContent = useMemo(() => (
		<div style={{height: window.innerHeight, overflowY: "scroll"}}>
			<div className="container main-content-container px-4">
				<Box className="row" m={2} style={{justifyContent: "flex-end"}}>
					<Box className="col" m={2} style={{top: "20px"}}>
						<Link to={{pathname: "/student/home"}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								style={{
									marginTop: "3px",
									marginLeft: "20px",
									cursor: "pointer",
								}}
								fill="none"
								stroke="gray"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-home"
							>
								<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
								<polyline points="9 22 9 12 15 12 15 22"></polyline>
							</svg>
						</Link>
						<p
							style={{
								float: "right",
								marginRight: "10px",
								fontSize: "20px",
								cursor: "pointer",
							}}
							onClick={() => doSignOut().then(() => props.history.push("/student/signin"))}
						>
							Log out
						</p>
					</Box>
				</Box>
				<div
					className="row"
					style={{paddingTop: "5%", justifyContent: "center"}}
				>
					<p className="page-title" style={{fontSize: "25px"}}>
						Choose a category...
					</p>
				</div>
				{CategoriesSelection}
				<div
					className="row"
					style={{paddingTop: "2%", justifyContent: "center"}}
				>
					<Box className="col" m={5}>
						<div className="row">
							<div className="col">
								<Link to={{pathname: "/student/practice"}}>
									<div className="row">
										<img
											src={require("../assets/images/left-arrow.png")}
											style={{height: "20px", marginTop: 5}}
										/>
										<p
											style={{
												color: "#192438",
												fontSize: "20px",
												marginLeft: 20,
											}}
										>
											Back
										</p>
									</div>
								</Link>
							</div>
							<div className="col">
								<div className="row" style={{justifyContent: "flex-end"}}>
									{!selectedCategory ? (
										NextArrow
									) : categoryToQuestionsMap[selectedCategory]?.length >= 0 ? (
										<Link
											to={{
												pathname: "/student/show-questions",
												state: {
													response: {
														category: selectedCategory,
													},
												},
												search: '?category=' + selectedCategory,
											}}
										>
											{/*paramsForNextView[0] */}
											{NextArrow}
										</Link>
									) : (
										<p>No Questions in this Category</p>
									)}
								</div>
							</div>
						</div>
					</Box>
				</div>
			</div>
		</div>
	), [CategoriesSelection, NextArrow, categoryToQuestionsMap, props.history, selectedCategory]);

	if (!pageLoaded) {
		return Loading;
	}
	return PageContent;
}

export default PracticeQuestionCategories;
