import {ExamQuestion, ExamStats, ExamTime, FirestoreUser} from '../firebase-listeners/interface';
import {firestore} from 'firebase/app';
import app from 'firebase/app';

export async function setExamQuestion(r: { category?: string, data: Partial<ExamQuestion>, id: string, merge?: boolean }): Promise<unknown> {
	return firestore().collection("Exam Questions").doc(r.id).set({
		...r.data,
		updatedISO: new Date().toISOString(),
		updated: app.firestore.FieldValue.serverTimestamp()
	}, {merge: r.merge})
}

export async function deleteExamQuestion(id: string): Promise<unknown> {
	const r = window.confirm("Are you sure you want to delete this Question?");
	if (r) {
		return firestore()
			.collection("Exam Questions")
			.doc(id)
			.delete()
	}
	return Promise.resolve();
}

export async function resetExamStats(r: { uid: string, examKey: string }): Promise<unknown> {
	return firestore().collection('User').doc(r.uid).set({examStats: {[r.examKey]: {}}}, {merge: true});
}

export async function setExamStats(r: { examKey: string, uid: string, examStats: ExamStats }): Promise<unknown> {
	return firestore().collection('User').doc(r.uid).set({examStats: {[r.examKey]: r.examStats}}, {merge: true});
}

export async function addSimulationAnsweredQuestions(r: { uid: string, questionId: string }): Promise<unknown> {
	return firestore()
		.collection("User")
		.doc(r.uid)
		.update({simulationAnsweredQuestions: app.firestore.FieldValue.arrayUnion(r.questionId)});
}

export async function addPracticeAnsweredQuestions(r: { uid: string, questionId: string }): Promise<unknown> {
	return firestore()
		.collection("User")
		.doc(r.uid)
		.update({practiceAnsweredQuestions: app.firestore.FieldValue.arrayUnion(r.questionId)});
}

export async function setUserExamTime(r: { uid: string, examTime: Omit<ExamTime, 'timerActive'>, examKey: string }): Promise<unknown> {
	return firestore()
		.collection("User")
		.doc(r.uid)
		.set({
			examStats: {
				[r.examKey]: {
					examTime: r.examTime,
				},
			}
		}, {merge: true});
}

// Created or updates the firestore user with the given uid
export async function setFirestoreUser(uid: string, user: Partial<FirestoreUser>): Promise<Partial<FirestoreUser>> {
	const firestoreUser = {...user, uid};
	await firestore().collection('User').doc(uid).set({
		...firestoreUser,
		updated: app.firestore.FieldValue.serverTimestamp(),
		updatedISO: new Date().toISOString(),
	}, {merge: true});
	return firestoreUser;
}
