import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ExamQuestion, QuizQuestion} from '../firebase-listeners/interface';
import {Exam} from '../components/Exam';
import {withRouter} from 'react-router-dom';
import {resetExamStats} from '../actions/firestore';
import {useCurrentUserId} from '../firebase-listeners/auth';
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {History} from 'history';
import {stringFromPossibleNullString} from '../helpers/helper-functions';
import {useCurrentFirestoreUser} from '../firebase-listeners/firestore-user';

export type Props = {
	location: {
		state: {
			response: {
				category: string | undefined
			}
		},
		search: string;
	};
	history: History;
}

export const ShowPracticeQuestions = (props: Props) => {
	const queryParams = new URLSearchParams(props.location.search);
	const category = stringFromPossibleNullString(queryParams.get('category'));
	const allQuestions = useExamQuestions();
	const user = useCurrentFirestoreUser();
	const uid = useCurrentUserId();
	const availableExam = user?.availableExam;
	const [filteredQuestions, setFilteredQuestions] = useState<ExamQuestion[]>((allQuestions || []).filter(q => !category || q.category === category));
	useEffect(() => {
		const filteredQuestions = (allQuestions || [])
			.filter(q => q?.questionType?.toLowerCase() === 'practice')
			.filter(q => q?.exam === availableExam);
		if (!category || category.toLowerCase() === 'all') {
			setFilteredQuestions(filteredQuestions);
		} else {
			setFilteredQuestions(filteredQuestions.filter(q => q.category === category));
		}
	}, [allQuestions, availableExam, category]);

	const startOver = useCallback((examKey: string) => {
		resetExamStats({uid: uid, examKey});
	}, [uid]);

	const restartExamUrl = `/student/show-questions?category=${category}`;

	return useMemo(() => (
		<Exam questions={filteredQuestions} examType={'Practice'} exam={''} category={category || ''} endButtonCTA={
			{
				label: 'Start Over',
				title: 'Questions you answer are removed from the list. If you want to re-visit them, click Start Over',
				onClick: startOver
			}
		}
			  showAnswerCard={true}
			  shouldShowReport={true}
			  restartExamUrl={restartExamUrl}
			  topBarTitle={`Practice - ${category || 'All Categories'}`}
			  history={props.history}
		/>
	), [category, filteredQuestions, props.history, restartExamUrl, startOver]);
}

export default withRouter(ShowPracticeQuestions as any);
