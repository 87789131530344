import React from 'react';
import {
    Container, Row, Col
} from "shards-react";
import HomepageButton from "../../components/Buttons/HomepageButton";
import {withFirebase} from "../../components/Firebase";
import {checkUserLoggedStatus} from "../../helpers/helper-functions";

export class AdminHomepage extends React.Component {

    constructor(props) {
        super(props);

        checkUserLoggedStatus()
    }

    render() {

        return (
            <div style={{ height: window.innerHeight, overflowY: 'scroll' }}>
                <Container className="main-content-container px-4">
                    <Row md={12}>
                        <Col style={{top: '20px'}}>
                            <p style={{float:'right', marginRight: '10px', fontSize: '20px', cursor: 'pointer'}} onClick={() => {
                                this.props.firebase.doSignOut()
                                    .then(() => { sessionStorage.setItem("userLoggedIn", "false") })
                                    .then(() => { window.location.href = '/student/signin' })
                            }}>Log out</p>
                        </Col>
                        
                    </Row>
                    <Col style={{justifyContent: 'center'}}>
                        <Row style={{justifyContent: 'center'}}>
                            <Col md={3} style={{justifyContent: 'center', marginTop: window.innerHeight/5}}>
                                <Row>
                                    <h1 className="page-title">Hello</h1>
                                    <h1 className="page-title" style={{color: '#42B1B2', marginLeft: 10}}>Dahlia!</h1>
                                </Row>
                                <Row>
                                    <h1 className="page-title">What would you like to do?</h1>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'center', marginTop: window.innerHeight/10}}>
                            <Col lg={15} style={{justifyContent: 'center'}}>
                                <Row style={{justifyContent: 'center', cursor: 'pointer'}}>
                                    <Col style={{justifyContent: 'center'}}>
                                        <HomepageButton message={'Add Quiz Questions'} link={'/student/admin-quiz-questions'}/>
                                    </Col>
                                    <Col style={{justifyContent: 'center', cursor: 'pointer'}}>
                                        {/* <HomepageButton message={'Add Exam Questions'} link={'/student/admin-exam-questions'}/> */}
                                        <HomepageButton message={'Exam Questions'} link={'/student/admin-exam-questions'}/>
                                    </Col>
                                </Row>
                                <Row style={{justifyContent: 'center', cursor: 'pointer'}}>
                                    <Col style={{justifyContent: 'center', cursor: 'pointer'}}>
                                        <HomepageButton message={'Add New Student'} link={'/student/admin-add-student'}/>
                                    </Col>
                                    <Col style={{justifyContent: 'center', cursor: 'pointer'}}>
                                        <HomepageButton message={'Update student\'s license'} link={'/student/admin-update-license'}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>

        );
    }
}

export default withFirebase(AdminHomepage);
