import React from "react";
import { Link } from "react-router-dom";

export default class HomepageButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let message = this.props.message;
    let link = this.props.link;

    let width = window.innerWidth / 4 + 100;

    return (
      <>
        {this.props.isChild ? (
          this.props.message == "Practice questions from all areas" ? (
            <Link
              to={{
                pathname: "/student/show-questions",
                query: {category: 'All'},
                state: {
                  response: { category: "All" },
                },
              }}
            >
              <p
                style={{
                  borderRadius: "15px",
                  fontSize: "20px",
                  background: "#F4F6F8",
                  paddingTop: "15px",
                  width: width - 50,
                  height: "60px",
                  color: "#192438",
                  textAlign: "center",
                }}
              >
                {message}
              </p>
            </Link>
          ) : this.props.message == "Review all material" ? (
            <Link
              to={{
                pathname: "/student/quiz",
                state: { response: this.props.response, category: "All" },
              }}
            >
              <p
                style={{
                  borderRadius: "15px",
                  fontSize: "20px",
                  background: "#F4F6F8",
                  paddingTop: "15px",
                  width: width - 50,
                  height: "60px",
                  color: "#192438",
                  textAlign: "center",
                }}
              >
                {message}
              </p>
            </Link>
          ) : (
            <Link
              to={{ pathname: link, state: { response: this.props.response } }}
            >
              <p
                style={{
                  borderRadius: "15px",
                  fontSize: "20px",
                  background: "#F4F6F8",
                  paddingTop: "15px",
                  width: width - 50,
                  height: "60px",
                  color: "#192438",
                  textAlign: "center",
                }}
              >
                {message}
              </p>
            </Link>
          )
        ) : link ? (
          <Link
            to={{ pathname: link, state: { response: this.props.response } }}
          >
            <p
              style={{
                borderRadius: "15px",
                fontSize: "20px",
                background: "rgba(138,208,209,0.33)",
                paddingTop: "15px",
                width: width,
                height: "60px",
                color: "#192438",
                textAlign: "center",
              }}
            >
              {message}
            </p>
          </Link>
        ) : (
          <p
            style={{
              borderRadius: "15px",
              fontSize: "20px",
              background: "rgba(138,208,209,0.33)",
              paddingTop: "15px",
              width: width,
              height: "60px",
              color: "#192438",
              textAlign: "center",
            }}
          >
            {message}
          </p>
        )}
      </>
    );
  }
}
