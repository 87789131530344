import React, {useCallback, useEffect, useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {withFirebase} from "../components/Firebase";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Container from "@material-ui/core/Container";
import logo from "../assets/images/logo-reqmaster.png";
import {signInWithEmailAndPassword} from '../actions/auth';
import {userIsAdmin} from '../helpers/helper-functions';
import {History} from 'history';
import {useIsUserLoggedIn} from '../firebase-listeners/auth';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export type Props = {
	history: History;
}

export const SignIn = (props: Props) => {
	const classes = useStyles();
	const isLoggedIn = useIsUserLoggedIn();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [submitting, setSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (isLoggedIn && !submitting) {
			props.history.push("/student/home");
		}
	}, [isLoggedIn, props.history, submitting]);

	// TODO: Ensure this still works properly
	const signIn = useCallback(async () => {
		setSubmitting(true);
		const user = await signInWithEmailAndPassword({email, password});
		if (userIsAdmin(user.uid)) {
			props.history.push("/student/admin");
			setSubmitting(false);
			return;
		}
		const expirationDate = new Date(user.licenseExpirationDate);
		const today = new Date();

		const timeBeforeExpiration = expirationDate.getTime() - today.getTime();

		// already expired
		if (timeBeforeExpiration < 0) {
			alert(`Please renew your license! Your license expired on ${user.licenseExpirationDate}!`);
			setSubmitting(false);
			return;
		}

		const expired7DaysOrLessFromNow = timeBeforeExpiration <= 7 * 24 * 60 * 60 * 1000;
		if (expired7DaysOrLessFromNow) {
			alert(`You have less than a week left! Your license will expire on ${user.licenseExpirationDate}!`);
		}
		props.history.push(`/student/home?firstname=${user.firstname}`);
		setSubmitting(false);
	}, [email, password, props.history]);

	const handleKeyPress = (e: { keyCode: number }) => {
		//it triggers by pressing the enter key
		if (e.keyCode === 13) {
			signIn();
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<img
				src={logo}
				style={{
					width: "270px",
					height: "50px",
					position: "absolute",
					left: "10px",
					top: "10px",
				}}
			/>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon/>
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} noValidate>
					<TextField
						onKeyDown={handleKeyPress}
						onChange={e => setEmail(e.target.value)}
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						onKeyDown={handleKeyPress}
						onChange={e => setPassword(e.target.value)}
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary"/>}
						label="Remember me"
					/>
					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={() => signIn()}
					>
						Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Link to={{pathname: "/student/reset-password"}}>
								Forgot password?
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);
}

export default withFirebase(SignIn);
