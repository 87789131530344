import React, {useCallback, useEffect, useMemo, useState} from "react";
import ShowAnsweredQuestions from "./ShowAnsweredQuestions";
import TopBar from "../components/TopBar";
// @ts-ignore
import {Button} from "shards-react";
import {resetExamStats} from "../actions/firestore";
import {ExamQuestion, ExamStats} from '../firebase-listeners/interface';
import {History} from 'history';

export type StateProps = {
	questions: ExamQuestion[];
	examStats: ExamStats
	category: string;
	exam: string;
	uid: string;
	examKey: string;
	restartExamUrl?: string;
	topBarTitle: string;
}

export type Props = {
	history: History;
	location: {
		state: StateProps;
	};
}

export type ExamReport = {
	correct: ExamQuestion[];
	incorrect: ExamQuestion[];
	skipped: ExamQuestion[];
	flagged: ExamQuestion[];
	successRate: number;
}

export const defaultExamReport: Readonly<ExamReport> = Object.freeze({
	correct: [],
	incorrect: [],
	skipped: [],
	flagged: [],
	successRate: 0,
})

const emptyObject = {};
const emptyArray: any[] = [];
export const Report = (props: Props) => {
	const state = props?.location?.state || emptyObject;
	const [showQuestions, setShowQuestions] = useState<boolean>(false);
	const [report, setReport] = useState<ExamReport>(defaultExamReport);
	const [questionsToShow, setQuestionsToShow] = useState<ExamQuestion[]>(emptyArray)
	useEffect(() => {
		const correct = state?.questions?.filter(q => state?.examStats?.answers?.[q.id] && state?.examStats?.answers?.[q.id] === q.choicesArray[q.correctChoiceIndex]) || [];
		const incorrect = state?.questions?.filter(q => state?.examStats?.answers?.[q.id] && state?.examStats?.answers?.[q.id] !== q.choicesArray[q.correctChoiceIndex]) || [];
		const skipped = state?.questions?.filter(q => !state?.examStats?.answers?.[q.id]);
		const flagged = state?.questions?.filter(q => state?.examStats?.markedQuestionIds?.indexOf(q.id) >= 0);
		const successRate = Math.round((correct.length / state?.questions?.length) * 100);
		setReport({
			correct,
			incorrect,
			skipped,
			flagged,
			successRate,
		});
	}, [state]);

	const showTheQuestions = useCallback((e: any, type: 'correct' | 'incorrect' | 'skipped' | 'flagged') => {
		e.preventDefault();
		switch (type) {
			case 'correct':
				setQuestionsToShow(report.correct);
				break;
			case 'incorrect':
				setQuestionsToShow(report.incorrect);
				break;
			case 'skipped':
				setQuestionsToShow(report.skipped);
				break;
			case 'flagged':
				setQuestionsToShow(report.flagged);
				break;
		}
		setShowQuestions(true);
	}, [report]);
	return useMemo(() => (
			<div className="container" style={{height: "inherit"}}>
				<TopBar
					title={props?.location?.state?.topBarTitle}
					history={props.history}
				/>
				<div className="mt-5">
					<p
						className="text-center"
						style={{
							fontSize: "30px",
						}}
					>
						Exam Results
					</p>
					<div
						className=" col-12"
						style={{border: "1px solid black", borderRadius: "25px"}}
					>
						<p
							style={{
								fontSize: "20px",
							}}
							className="mt-4"
						>
							Correct answers:{" "}
							<a
								onClick={(e) => {
									showTheQuestions(e, "correct");
								}}
								href="/"
							>
								{report?.correct?.length}
							</a>
						</p>
						<p
							style={{
								fontSize: "20px",
							}}
						>
							Incorrect answers:{" "}
							<a
								onClick={(e) => {
									showTheQuestions(e, "incorrect");
								}}
								href="/"
							>
								{report?.incorrect?.length}
							</a>
						</p>
						<p
							style={{
								fontSize: "20px",
							}}
						>
							Skipped questions:{" "}
							<a
								onClick={(e) => {
									showTheQuestions(e, "skipped");
								}}
								href="/"
							>
								{report?.skipped?.length}
							</a>
						</p>
						<p
							style={{
								fontSize: "20px",
							}}
						>
							Marked questions:{" "}
							<a
								onClick={(e) => {
									showTheQuestions(e, "flagged");
								}}
								href="/"
							>
								{report?.flagged?.length}
							</a>
						</p>
						<p
							style={{
								fontSize: "20px",
							}}
						>
							Success Percentage:{" "}
							<a className="button-link">
								{isNaN(report?.successRate)
									? "No attempts"
									: report?.successRate + "%"}
							</a>
						</p>
						<p>
							<Button style={{
								fontSize: "20px",
							}} onClick={() => {
								resetExamStats({uid: state?.uid, examKey: state?.examKey}).then(() => {
									if (state?.restartExamUrl) {
										props.history.push(state?.restartExamUrl);
									} else {
										props.history.goBack();
									}
								});
							}}>Retry Exam</Button>
						</p>
					</div>
				</div>
				<div className="row-7">
					{showQuestions && questionsToShow?.length > 0 ? (
						<ShowAnsweredQuestions
							answers={state?.examStats?.answers}
							questions={questionsToShow}
						/>
					) : null}
				</div>
			</div>
	), [props, questionsToShow, report, showQuestions, showTheQuestions, state]);
}

export default Report;
