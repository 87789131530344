import React from 'react';
import {doSignOut} from "../actions/auth";
import {Link} from 'react-router-dom';
import {History} from 'history';

export type Props = {
	title: string;
	history: History;
}

export const TopBar = (props: Props) => {
	return (
		<div className="row" style={{background: '#F4F6F8', justifyContent: 'space-between'}}>
			<div className="row">
				<p className="exam-title">{props.title}</p>

			</div>
			<div className="row">
				<div className="col" style={{top: '10px'}}>
					<div className="row">
						<Link to={{pathname: '/student/home'}}>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
								 style={{marginTop: '3px', marginRight: '20px', cursor: 'pointer'}} fill="none"
								 stroke="gray"
								 strokeWidth="2" strokeLinecap="round"
								 strokeLinejoin="round" className="feather feather-home">
								<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
								<polyline points="9 22 9 12 15 12 15 22"></polyline>
							</svg>
						</Link>
						<p style={{marginRight: '40px', fontSize: '20px', cursor: 'pointer'}}
						   onClick={() => doSignOut().then(() => props.history.push('/student/signin'))}>Log out</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TopBar;
