import React, {useCallback, useMemo, useState} from "react";
// @ts-ignore
import {Container, Row, Col} from "shards-react";
import HomepageButton from "../components/Buttons/HomepageButton";
import {withFirebase} from "../components/Firebase";
// @ts-ignore
import {SolarSystemLoading} from "react-loadingg";
import {Link} from "react-router-dom";
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {doSignOut} from '../actions/auth';
import {History} from 'history';
import {useSequenceCategories} from '../firebase-listeners/sequence-categories';

export type Props = {
	history: History;
}

export const PracticeQuestions = (props: Props) => {
	const questions = useExamQuestions();
	const categories = useSequenceCategories();
	const questionsLoaded = questions && questions.length > 0;
	const [clickedPracticeQuestions, setClickedPracticeQuestions] = useState<boolean>(false);

	const Loading = useMemo(() => (
		<SolarSystemLoading
			style={{position: "fixed", top: "50%", left: "50%"}}
		/>
	), []);

	const togglePracticeQuestions = useCallback(() => setClickedPracticeQuestions(!clickedPracticeQuestions), [clickedPracticeQuestions]);

	return !questionsLoaded ? Loading : (
		<div id="dpi" style={{height: window.innerHeight, overflowY: "scroll"}}>
			<Container className="main-content-container px-4">
				<Row md={2} style={{justifyContent: "flex-end"}}>
					<Col md={2} style={{top: "20px"}}>
						<Link to={{pathname: "/student/home"}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								style={{
									marginTop: "3px",
									marginLeft: "20px",
									cursor: "pointer",
								}}
								fill="none"
								stroke="gray"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-home"
							>
								<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
								<polyline points="9 22 9 12 15 12 15 22"></polyline>
							</svg>
						</Link>
						<p
							style={{
								float: "right",
								marginRight: "10px",
								fontSize: "20px",
								cursor: "pointer",
							}}
							onClick={() => doSignOut().then(() => props.history.push("/student/signin"))}
						>
							Log out
						</p>
					</Col>
				</Row>

				<Row
					style={{
						justifyContent: "center",
						marginTop: window.innerHeight / 3,
					}}
				>
					<Col
						sm={5}
						style={{cursor: "pointer"}}
						onClick={togglePracticeQuestions}
					>
						<HomepageButton message={"Practice Questions"}/>
						{clickedPracticeQuestions ? (
							<>
								<Row style={{justifyContent: "center"}}>
									<HomepageButton
										message={"Practice questions by knowledge area"}
										link={"/student/practice-categories"}
										isChild={true}
										response={{
											categories: categories,
											questions: questions,
										}}
									/>
								</Row>
								{}
								<Row style={{justifyContent: "center"}}>
									<HomepageButton
										message={"Practice questions from all areas"}
										link={"/student/show-questions?category=All"}
										isChild={true}
										response={questions}
									/>
								</Row>
							</>
						) : (
							<></>
						)}
					</Col>
					<Col sm={2}></Col>
					<Col sm={5}>
						<HomepageButton
							message={"Take a real exam simulation (timed)"}
							link={"/student/simulation-detailed"}
							response={questions}
						/>
					</Col>
				</Row>
				<Row
					style={{
						justifyContent: "center",
						marginTop: window.innerHeight / 10,
					}}
				>
					<Link to={{pathname: "/student/home"}} style={{marginLeft: "-30px"}}>
						<Row style={{justifyContent: "center"}}>
							<img
								src={require("../assets/images/left-arrow.png")}
								style={{height: "20px", marginTop: 5}}
							/>
							<p
								style={{color: "#192438", fontSize: "20px", marginLeft: 20}}
							>
								Back
							</p>
						</Row>
					</Link>
				</Row>
			</Container>
		</div>
	);
}

export default withFirebase(PracticeQuestions);
