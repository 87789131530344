import React, { Component } from 'react';

import { checkUserLoggedStatus } from '../helpers/helper-functions';
import 'bootstrap';
import { Link } from 'react-router-dom';
import { withFirebase } from "../components/Firebase";
import CategoryChoiceBox from "../components/Selections/CategoryChoiceBox";
import { SolarSystemLoading } from "react-loadingg";


var categories = ["Techniques 1-5", "Techniques 6-10", "Techniques 11-15", "Techniques 16-20", "Techniques 21-25", "Techniques 26-30", "Techniques 31-35", "Techniques 36-40", "Techniques 41-45", "Techniques 46-50"]
var questions = []

class Subcategories extends Component {
    constructor(props) {
        super(props);

        checkUserLoggedStatus();
        this.state = {
            checked: null,
    
        }
        document.title = "Subcategories";
    }

    

    // state = {
    //     isPageLoaded: false
    // }

    // fetchQuestionsFromDb = (category, answeredQuestionsIds) => {
    //     this.props.firebase
    //         .doGetExamQuestionsByCategory(category)
    //         .then(res => {
    //             var newArray = res.filter((item, index) => res.indexOf(item) === index)
    //             var notAnsweredQuestions;
    //             if (answeredQuestionsIds) {
    //                 notAnsweredQuestions = newArray.filter(v => {
    //                     var result = answeredQuestionsIds.find(answeredId => answeredId == v.id)
    //                     if (result == undefined) {
    //                         return true
    //                     } else {
    //                         return false
    //                     }
    //                 })
    //             } else {
    //                 notAnsweredQuestions = newArray
    //             }
    //             questions = {
    //                 ...questions,
    //                 [category]: notAnsweredQuestions//  notAnsweredQuestions
    //             }
    //         })
    //         .then(final => {
    //             this.setState({ isPageLoadedQuestions: true })
    //         })
    //         .catch(error => {
    //             console.log("Error" + error)
    //         });
    // }

    // getAnsweredQuestions = () => {
    //     this.props.firebase.getAnsweredQuestionsIds().then(v => v).then(v =>
    //         this.getCategories(v))
    // }

    componentDidMount() {
        questions = this.props.location.state.questions
        // this.getAnsweredQuestions()

    }

    // getCategories = (answeredQuestions) => {
    //     this.props.firebase.getCategories().then(res => categories = res)
    //         .then(() => {
    //             categories.map((v) => {
    //                 this.fetchQuestionsFromDb(v, answeredQuestions)
    //             })
    //         })
    //         .then(() => this.setState({ isPageLoaded: true }));
    // }

    setChecked = (category) => {
        this.setState({ checked: category })
    }
    clearChecks() {
        this.setState({ checked: false })
    }



    render() {
        var categoriesJSX = null;
        if (categories.length !== 0 && categories) {
            categoriesJSX = categories.map((v, i) => (
                <div key={i} className="row" style={{ justifyContent: 'center' }}>
                    <div onClick={() => { this.clearChecks(); this.setChecked(v) }}>
                        <CategoryChoiceBox message={v} checked={this.state.checked === v ? true : false} />
                    </div>
                </div>
            ))
            // categoriesJSX = (
            //     <div key={i} className="row" style={{ justifyContent: 'center' }}>
            //         <div onClick={() => { this.clearChecks(); this.setChecked(v) }}>
            //             <CategoryChoiceBox message={"Techniques 1-5"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 6-10"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 11-15"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 16-20"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 21-25"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 26-30"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 31-35"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 36-40"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 41-45"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //             <CategoryChoiceBox message={"Techniques 45-50"} checked={this.state.checked === "Techniques 1-5" ? true : false} />
            //         </div>
            //     </div>
            // )
        }
        let content

        if (!questions) {
            content = <SolarSystemLoading style={{ position: 'fixed', top: '50%', left: '50%' }} />;
        } else {
            content = <div style={{ height: window.innerHeight, overflowY: 'scroll' }}>
                <div className="container main-content-container px-4">
                    <div className="row" md={2} style={{ justifyContent: 'flex-end' }}>
                        <div className="col" md={2} style={{ top: '20px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                style={{ marginTop: '3px', marginLeft: '20px', cursor: 'pointer' }} fill="none" stroke="gray"
                                strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" className="feather feather-home" onClick={() => {
                                    window.location.href = '/student/home'
                                }}>
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <p style={{ float: 'right', marginRight: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={() => {
                                this.props.firebase.doSignOut()
                                    .then(() => { sessionStorage.setItem("userLoggedIn", "false") })
                                    .then(() => { window.location.href = '/student/signin' })
                            }}>Log out</p>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: '5%', justifyContent: 'center' }}>
                        <p className="page-title" style={{ fontSize: '25px' }}>Choose a category...</p>
                    </div>
                    {categoriesJSX}
                    <div className="row" style={{ paddingTop: '2%', justifyContent: 'center' }}>
                        <div className="col" lg={5}>
                            <div className="row">
                                <div className="col">
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.goBack();
                                    }}>
                                        <div className="row">
                                            <img src={require('../assets/images/left-arrow.png')} style={{ height: '20px', marginTop: 5 }} />
                                            <p style={{ color: '#192438', fontSize: '20px', marginLeft: 20 }}>Back</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <div className="row" style={{ justifyContent: 'flex-end' }}>
                                        {
                                            !this.state.checked ?
                                                <div className="row">
                                                    <p style={{ color: '#192438', fontSize: '20px', marginRight: 20 }}>Next</p>
                                                    <img src={require('../assets/images/right-arrow.png')} style={{ height: '20px', marginTop: 5 }} />
                                                </div>
                                                :
                                                questions[this.state.checked].length !== 0 ? <Link to={{ pathname: '/student/quiz', state: { response: questions[this.state.checked], category: this.state.checked } }}>
                                                    {/*paramsForNextView[0] */}
                                                    <div className="row">
                                                        <p style={{ color: '#192438', fontSize: '20px', marginRight: 20 }}>Next</p>
                                                        <img src={require('../assets/images/right-arrow.png')} style={{ height: '20px', marginTop: 5 }} />
                                                    </div>
                                                </Link> : <p>No Quesions in this Category</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        }

        return (
            <>
                {content}
            </>
        );

    }
}

export default withFirebase(Subcategories);
