

export function noCTRL(e) {
	const code = (document.all) ? window.event.keyCode : e.which;
	const ctrl = (document.all) ? window.event.ctrlKey : e.modifiers & Event.CONTROL_MASK;
	const msg = "Sorry, this functionality is disabled.";
	if (document.all) {
		if (ctrl && code == 86) {
			//CTRL+V
			alert(msg);
			window.event.returnValue = false;
		} else if (ctrl && code == 67) {
			//CTRL+C (Copy)
			alert(msg);
			window.event.returnValue = false;
		}
	} else {
		if (ctrl == 2) {
			//CTRL key
			alert(msg);
			return false;
		}
	}
}
