import React from "react";
import { Redirect } from "react-router-dom";

// Route Views
import StudentHomepage from "./views/StudentHomepage";
import PracticeQuestions from "./views/PracticeQuestions";
import SimulationQuestionsDetailed from "./views/SimulationQuestionsDetailed";
import PracticeQuestionsCategories from "./views/PracticeQuestionsCategories";
import ReviewCategories from "./views/ReviewCategories";
import AdminHomepage from "./views/admin/AdminHomepage";
import AdminQuizQuestions from "./views/admin/AdminQuizQuestions";
import AddExamQuestion from "./views/admin/AddExamQuestion";
import AdminAddStudent from "./views/admin/AdminAddStudent";
import AdminUpdateLicense from "./views/admin/AdminUpdateLicense";
import ForgotPassword from "./views/ForgotPassword";
import SignIn from "./views/SignIn";
import AdminExamQuestions from './views/admin/AdminExamQuestions'
// import SignUp from "./views/SignUp";
import EditExamQuestion from './views/admin/EditExamQuestion';
import ShowPracticeQuestions from './views/ShowPracticeQuestions';
import ShowSimulationQuestions from "./views/ShowSimulationQuestions";
import Report from './views/Report';
import Subcategories from './views/Subcategories'
import {Quiz} from "./views/Quiz";

export default [

    // Homepage
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/student/signin" />
    },
    {
        path: "/student",
        exact: true,
        component: () => <Redirect to="/student/signin" />
    },
    {
        path: "/student/home",
        component: StudentHomepage
    },
    {
        path: "/student/practice",
        component: PracticeQuestions
    },
    {
        path: "/student/simulation-detailed",
        component: SimulationQuestionsDetailed
    },
    {
        path: "/student/practice-categories",
        component: PracticeQuestionsCategories
    },
    {
        path: "/student/review-categories",
        component: ReviewCategories
    },
    {
        path: "/student/admin",
        component: AdminHomepage
    },
    {
        path: "/student/admin-quiz-questions",
        component: AdminQuizQuestions
    },
    {
        path: "/student/add-exam-question",
        component: AddExamQuestion
    },
    {
        path: "/student/signin",
        component: SignIn
    },
    // {
    //     path: "/student/signup",
    //     component: SignUp
    // },
    {
        path: "/student/reset-password",
        component: ForgotPassword
    },
    {
        path: "/student/admin-add-student",
        component: AdminAddStudent
    },
    {
        path: "/student/admin-update-license",
        component: AdminUpdateLicense
    },
    {
        path: '/student/admin-exam-questions',
        component: AdminExamQuestions
    },
    {
        path: '/student/edit-exam-question/:questionId',
        component: EditExamQuestion
    },
    {
        path: "/student/show-questions",
        component: ShowPracticeQuestions
    },
    {
        path: "/student/show-simulation-questions/:exam",
        component: ShowSimulationQuestions
    },
    {
        path: "/student/report",
        component: Report
    },
    {
        path: "/student/quiz/:category?",
        component: Quiz
    },
    {
        path: "/student/subcategories",
        component: Subcategories
    },
];
