import * as React from 'react';
import {noCTRL} from "./noCtrl";

export type PreventCopyPasteProps = { children: React.ReactNode[] | React.ReactNode }

export const noCopyPasteProps = {
	style: {userSelect: 'none' as 'none', msUserSelect: 'none' as 'none', mosUserSelect: 'none', webkitUserSelect: 'none'},
	onMouseDown: () => false,
	onSelect: () => false,
	onPaste: () => false,
	onCut: () => false,
	onContextMenu: () => false,
	onCopy: () => false,
	onKeyDown: (e: any) => noCTRL(e),
} as const;

export const PreventCopyPaste = (props: PreventCopyPasteProps) => {
	if (!props.children) {
		return <></>
	}
	const childProps = {...noCopyPasteProps} as any;
	delete childProps.style;
	if (Array.isArray(props.children)) {
		return <div {...noCopyPasteProps}>{props.children.map(child => React.cloneElement(child as any, childProps ))}</div>;
	}
	if (typeof props.children === 'string') {
		return <span {...noCopyPasteProps}>{props.children}</span>
	}
	return <div {...noCopyPasteProps}>{React.cloneElement(props.children as any, childProps )}</div>
}

export const PreventCopyPasteSpan = (props: PreventCopyPasteProps) => {
	if (!props.children) {
		return <></>
	}
	const childProps = {...noCopyPasteProps} as any;
	delete childProps.style;
	if (Array.isArray(props.children)) {
		return <span {...noCopyPasteProps}>{props.children.map(child => React.cloneElement(child as any, childProps ))}</span>;
	}
	return <span {...noCopyPasteProps}>React.cloneElement(child as any, childProps )</span>
}
