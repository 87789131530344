import React from "react";
import { Container, Row, Col } from "shards-react";
import HomepageButton from "../components/Buttons/HomepageButton";
import { withFirebase } from "../components/Firebase";
import { checkUserLoggedStatus } from "../helpers/helper-functions";
import logo from "../assets/images/logo-reqmaster.png";

let response_All = [];

export class StudentHomepage extends React.Component {
  constructor(props) {
    super(props);

    checkUserLoggedStatus();

    this.state = {
      clickedReviewQuestions: false,
      spinnerSuccess: null,
      firstName: null,
    };

    this.setClickedReviewQuestions = this.setClickedReviewQuestions.bind(this);
  }

  componentDidMount() {
    response_All = [];
    document.title = "Reqmaster";

    const search = this.props.location.search; // returns the URL query String
    const params = new URLSearchParams(search);
    var firstName = params.get("firstname");
    if (!firstName) {
      if (sessionStorage.getItem("userFirstName")) {
        this.setState({ firstName: sessionStorage.getItem("userFirstName") });
      }
    } else {
      this.setState({ firstName });
    }
  }

  setClickedReviewQuestions() {
    this.state.clickedReviewQuestions
      ? this.setState({ clickedReviewQuestions: false })
      : this.setState({ clickedReviewQuestions: true });
  }

  render() {
    var currentQuestion = this.state.currentQuestion;
    // var image = new Image();
    // if (questions[currentQuestion].picture != "NAAN" && questions[currentQuestion].picture) {
    //     image.src = questions[currentQuestion].picture;
    // } else {
    //     image = null;
    // }

    return (
      <div style={{ height: window.innerHeight, overflowY: "scroll" }}>
        {/* {image} */}

        <Container className="main-content-container px-4">
          <img
            src={logo}
            style={{
              width: "270px",
              height: "50px",
              position: "absolute",
              left: "10px",
              top: "10px",
            }}
          ></img>

          <Row md={12}>
            <Col style={{ top: "20px" }}>
              <p
                style={{
                  float: "right",
                  marginLeft: "10px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.props.firebase
                    .doSignOut()
                    .then(() => {
                      sessionStorage.setItem("userLoggedIn", "false");
                    })
                    .then(() => {
                      window.location.href = "/student/signin";
                    });
                }}
              >
                Log out
              </p>
            </Col>
          </Row>
          <Col style={{ justifyContent: "center" }}>
            <Row style={{ justifyContent: "center" }}>
              <Col
                md={4}
                style={{
                  justifyContent: "center",
                  marginTop: window.innerHeight / 5,
                }}
              >
                <Row>
                  <h1 className="page-title">Hello</h1>
                  <h1
                    className="page-title"
                    style={{ color: "#42B1B2", marginLeft: 10 }}
                  >
                    {this.state.firstName}!
                  </h1>
                </Row>
                <Row>
                  <h1 className="page-title">What do you want to do today?</h1>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: "space-between",
                marginTop: window.innerHeight / 10,
              }}
            >
              <Col sm={5}>
                <HomepageButton
                  message={"Practice Exam Questions"}
                  link={"/student/practice"}
                />
              </Col>
              {/*<Col sm={2}/>*/}
              <Col
                sm={5}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setClickedReviewQuestions();
                }}
              >
                <HomepageButton
                  message={"Review the Material with Quiz Questions"}
                />
                {this.state.clickedReviewQuestions ? (
                  <>
                    <Row style={{ justifyContent: "center" }}>
                      <HomepageButton
                        isChild={true}
                        message={"Review by knowledge area"}
                        link={"/student/review-categories"}
                      />
                    </Row>
                    <Row style={{ justifyContent: "center" }}>
                      <HomepageButton
                        isChild={true}
                        message={"Review all material"}
                        link={"/student/quiz"}
                        response={response_All[0]}
                      />
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    );
  }
}

export default withFirebase(StudentHomepage);
