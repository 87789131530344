import {QuizQuestion} from './interface';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {firestore} from 'firebase/app';
import {useIsUserLoggedIn} from './auth';

// Globally store Exam Questions to avoid excessive firestore calls
let listeners: Dispatch<SetStateAction<string[]>>[] = [];
let sequenceCategories: string[] = [];
let cancelSubscription: (() => unknown) | undefined = undefined;


const setCategories = (newState: string[]) => {
	sequenceCategories = newState;
	listeners.forEach((listener) => {
		listener(sequenceCategories);
	});
};

export function useSequenceCategories(): string[] {
	const isLoggedIn = useIsUserLoggedIn();
	const newListener = useState(sequenceCategories)[1];

	useEffect(() => {
		listeners.push(newListener);
	}, [newListener]);

	useEffect(() => {
		// Already listening
		if (isLoggedIn && cancelSubscription) {
			return;
		}
		// Logged out, and is not listening
		if (!isLoggedIn && !cancelSubscription) {
			return;
		}

		// Logged in, not listening yet
		if (isLoggedIn && !cancelSubscription) {
			cancelSubscription = firestore()
				.collection('Category')
				.doc('Sequence')
				.onSnapshot({
					next: (doc: firestore.DocumentSnapshot) => {
						setCategories(doc.data()?.Categories || []);
					},
					error: (error: firestore.FirestoreError) => {
						console.error(`got firestore error reading sequence exam categories: ${error}`);
					}
				})
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			// only unsubscribe if we are logged out. Keeping the subscription alive does not have bad consequences
			if (!isLoggedIn && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
			}
		}
	}, [isLoggedIn, newListener]);
	return sequenceCategories;
}
