import React, {useCallback, useState} from 'react';
// @ts-ignore
import {Container, Row, Col, FormSelect, FormInput, Button} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import {createUserWithEmailAndPassword, signOut} from '../../actions/auth';
import {History} from 'history';

const examTypeArray = ["CBAP", "CCBA", "ECBA"]

type FormData = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	examType: string;
	licenseExpirationDate: string;
}

function getDefaultFormData(): FormData {
	return {
		licenseExpirationDate: '',
		email: '',
		examType: '',
		firstName: '',
		lastName: '',
		password: '',
	};
}

type Event = {target: {value: string}};

export const AdminAddStudent = (props: {history: History}) => {
	const [formData, setFormData] = useState<FormData>(getDefaultFormData());

	const setFormValue = useCallback((data: Partial<FormData>) => {
		setFormData({...formData, ...data});
	}, [formData]);

	const createUser = useCallback(async () => {
		try {
			await createUserWithEmailAndPassword({
				email: formData.email, password: formData.password, user: {
					firstname: formData.firstName,
					lastname: formData.lastName,
					email: formData.email,
					availableExam: formData.examType,
					licenseExpirationDate: formData.licenseExpirationDate
				}
			});
			alert(`successfully created user ${formData.email} - need to sign you out`);
			await signOut();
			props.history.push('/student/signin');
		} catch (e) {
			alert(`could not create user.. got error: ${e}`);
		}
	}, [formData.email, formData.examType, formData.firstName, formData.lastName, formData.licenseExpirationDate, formData.password, props.history]);

	return (
		<div style={{height: window.innerHeight, overflowY: 'scroll'}}>
			<Container className="main-content-container px-4">
				<div
					onClick={() => window.history.back()}
					style={{
						color: "blue",
						cursor: "pointer",
						textDecoration: "underline",
					}}
				>
					Go back
				</div>
				<Col style={{justifyContent: 'center'}}>
					<Row style={{justifyContent: 'center'}}>
						<Col md={10} style={{justifyContent: 'center', marginTop: '30px'}}>
							<Row style={{marginTop: '30px'}}>
								<Col md={3}>
									<label htmlFor="feInputName">First Name</label>
									<FormInput id="firstname" type="text" placeholder='eq: Joe' onChange={(e: Event) => setFormValue({firstName: e.target.value})}/>
								</Col>
								<Col md={3}>
									<label htmlFor="feInputName">Last Name</label>
									<FormInput id="lastname" type="text" onChange={(e: Event) => setFormValue({lastName: e.target.value})}/>
								</Col>
								<Col md={3}>
									<label htmlFor="feInputName">Student's Available Exam</label>
									<FormSelect id="exam_type" onChange={(e: Event) => setFormValue({examType: e.target.value})}>
										<option>Choice...</option>
										{examTypeArray.map((type, index) =>
											<option key={index}>{type}</option>
										)}
									</FormSelect>
								</Col>
							</Row>
							<Row style={{marginTop: '30px'}}>
								<Col md={4}>
									<label htmlFor="feInputName">Student's Email</label>
									<FormInput id="email" type="email" placeholder='eq: reqmasterco@gmail.com' onChange={(e: Event) => setFormValue({email: e.target.value})}/>
								</Col>
								<Col md={4}>
									<label htmlFor="feInputName">Student's Password</label>
									<FormInput id="password" type="password" placeholder='eq: Bogdanco2' onChange={(e: Event) => setFormValue({password: e.target.value})}/>
								</Col>
								<Col md={4}>
									<label htmlFor="feInputName">Student's License Expiration Date</label>
									<RangeDatePicker id="date" value onChange={(e: Event) => setFormValue({licenseExpirationDate: e.target.value})}/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{justifyContent: 'center', marginTop: '60px'}}>
						<Col md={10}>
							<Button size="md" style={{width: '200px'}} onClick={createUser}>
								Add User
							</Button>
						</Col>
					</Row>
				</Col>
			</Container>
		</div>

	);
}

export default AdminAddStudent;
