import {QuizQuestion} from './interface';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {firestore} from 'firebase/app';
import {useIsUserLoggedIn} from './auth';

// Globally store Exam Questions to avoid excessive firestore calls
let listeners: Dispatch<SetStateAction<QuizQuestion[]>>[] = [];
let questions: QuizQuestion[] = [];
let cancelSubscription: (() => unknown) | undefined = undefined;


const setQuestions = (newState: QuizQuestion[]) => {
	questions = newState;
	listeners.forEach((listener) => {
		listener(questions);
	});
};

export function useQuizQuestions(): QuizQuestion[] {
	const isLoggedIn = useIsUserLoggedIn();
	const newListener = useState(questions)[1];

	useEffect(() => {
		listeners.push(newListener);
	}, [newListener]);

	useEffect(() => {
		// Already listening
		if (isLoggedIn && cancelSubscription) {
			return;
		}
		// Logged out, and is not listening
		if (!isLoggedIn && !cancelSubscription) {
			return;
		}
		// Logged in, not listening yet
		if (isLoggedIn && !cancelSubscription) {
			cancelSubscription = firestore()
				.collection('Quiz Questions')
				.onSnapshot({
					next: (snapshot: firestore.QuerySnapshot) => {
						setQuestions((snapshot.docs || []).map(d => d.data() as QuizQuestion));
					},
					error: (error: firestore.FirestoreError) => {
						console.error(`got firestore error reading exam questions: ${error}`);
					}
				})
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			// only unsubscribe if we are logged out. Keeping the subscription alive does not have bad consequences
			if (!isLoggedIn && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
			}
		}
	}, [isLoggedIn, newListener]);
	return questions;
}
