import {auth, User} from 'firebase/app';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

// Globally store  User to avoid excessive function calls
let listeners: Dispatch<SetStateAction<User | null>>[] = [];
let currentUser: User | null = null;
let cancelSubscription: (() => unknown) | undefined = undefined;


const setCurrentUser = (newState: User | null) => {
	currentUser = newState;
	listeners.forEach((listener) => {
		listener(currentUser);
	});
};


export function useCurrentUser(): User | null {
	const newListener = useState(currentUser)[1];
	useEffect(() => {
		listeners.push(newListener);
		if (listeners.length === 1) {
			cancelSubscription = auth().onAuthStateChanged(user => {
				setCurrentUser(user);
			});
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			if (listeners.length === 0 && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
			}
		}
	}, [newListener]);
	return currentUser;
}

export function useIsUserLoggedIn(): boolean {
	const user = useCurrentUser();
	const [isLoggedIn, setIsLoggedIn] = useState(Boolean(user));
	useEffect(() => setIsLoggedIn(Boolean(user)), [user]);
	return isLoggedIn;
}

export function useCurrentUserId(): string {
	const user = useCurrentUser();
	const [uid, setUid] = useState(user?.uid || '');
	useEffect(() => setUid(user?.uid || ''), [user]);
	return uid;
}
