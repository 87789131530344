import React, {Component, useCallback, useState} from "react";
import { withFirebase } from "../components/Firebase";
import {ExamQuestion, QuizQuestion} from '../firebase-listeners/interface';
import {addPracticeAnsweredQuestions, addSimulationAnsweredQuestions} from '../actions/firestore';
import {useCurrentUserId} from '../firebase-listeners/auth';

export type Props = {
  currentQuestionId: string;
  currentQuestionNumber: number;
  totalQuestions: number;
  
  isFromShowSimulationQuestions?: boolean;
  isFromShowQuestions?: boolean;
  
  setCurrentQuestion: (currentQuestionNumber: number) => unknown;
  showReport?: () => unknown;
}

export const QuestionsNavigation = (props: Props) => {
  const uid = useCurrentUserId();

  const changeQuestion = useCallback((direction: 'next' | 'previous') => {
    if (props.isFromShowSimulationQuestions) {
      addSimulationAnsweredQuestions({uid, questionId: props.currentQuestionId}); // -----
    }
    if (props.isFromShowQuestions === true) {
      addPracticeAnsweredQuestions({uid, questionId: props.currentQuestionId});
    }

    if (direction === "next" && props.totalQuestions === props.currentQuestionNumber + 1 && props.showReport) {
      props.showReport();
    }
    
    let nextQuestionNumber: number = props.currentQuestionNumber;
    if (direction === "next" && props.totalQuestions !== props.currentQuestionNumber + 1) {
      nextQuestionNumber = props.currentQuestionNumber + 1;
    } else if (direction === "previous" && props.currentQuestionNumber !== 0) {
      nextQuestionNumber = props.currentQuestionNumber - 1;
    }
    props.setCurrentQuestion(nextQuestionNumber);
    

  }, [props, uid]);

  return (
      <div>
        {(
            <img
                onClick={() => changeQuestion("previous")}
                src={require("../assets/images/left-arrow.png")}
                style={{
                  height: "20px",
                  marginTop: 5,
                  marginRight: "0px",
                  position: "absolute",
                  bottom: "-40px",
                  right: "70px",
                  cursor: "pointer",
                }}
            />
        )}
        {(
            <img
                onClick={() => changeQuestion("next")}
                src={require("../assets/images/right-arrow.png")}
                style={{
                  height: "20px",
                  marginTop: 5,
                  marginRight: "0px",
                  position: "absolute",
                  bottom: "-40px",
                  right: "30px",
                  cursor: "pointer",
                }}
            />
        )}
      </div>
  );
}

export default QuestionsNavigation;
