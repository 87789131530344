import React, {useCallback, useState} from "react";
import {ExamQuestion, ExamTime, QuizQuestion} from '../firebase-listeners/interface';
import {getCurrentUserId} from '../actions/auth';

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export async function checkUserLoggedStatus() {
    const loggedInViaSessionStorage = sessionStorage.getItem('userLoggedIn') === 'true';
    const loggedInViaAuth = await getCurrentUserId();
    if(!loggedInViaSessionStorage && !loggedInViaAuth) {
        window.location.href = '/student/signin'
    }
}

export function createArrayWithNullStrings(num: number): Array<'null'> {

    let numbers = Array.from(Array(num), (_, i) => i + 1);
    let finalArray: Array<'null'> = []
    numbers.forEach(transformToNullString);

    function transformToNullString(item: any) {
        finalArray = [...finalArray, 'null']
    }

    return finalArray;
}

export function replaceMissingWordWithUnderlines(text: string) {

    let alteredText = text.replace(/%missing_word%/g, '_______')
    return <p className="noselect">{alteredText}</p>
}

export function calculateMatchingPercentageBetweenTwoArrays(correctAnswers: string[], answers: string[]) {

    let numberOfMatchingAnswers = 0;
    correctAnswers.forEach(calculatePercentage);

    function calculatePercentage(value: string, index: number, array: string[]) {
        if(value === answers[index+1])
            numberOfMatchingAnswers += 1;
    }

    let percentage = (numberOfMatchingAnswers*100)/correctAnswers.length
    return Math.round(percentage)
}

export function getCurrentDate() {

    let today = new Date();
    let dateString = today.toString()
    return dateString.substring(7, 21)
}

export function smoothScroll(elementId: string) {

    let elmntToView = document.getElementById(elementId);
    (elmntToView as any).scrollIntoView({behavior: "smooth", block: "center"});
}

export function roundFloat(float: number): string {
    return float.toString().slice(0,3)
}

export function calculateTimePassedUntilNow(initialTotalTime: any) {

    let remainingTime = document.querySelector("#timer") as any;
    let from = remainingTime.innerHTML.indexOf("Time left") + 13
    let remainingTotalTime = remainingTime.innerHTML.substring(from, from + 8)
    let remainingHours = remainingTotalTime.slice(0,2)
    let remainingMinutes = remainingTotalTime.slice(3,5)
    let remainingSeconds = remainingTotalTime.slice(6,8)
    let initialHours = initialTotalTime.slice(0,2)
    let initialMinutes = initialTotalTime.slice(3,5)
    let initialSeconds = initialTotalTime.slice(6,8)


    let remainingTotalSeconds = (parseInt(remainingHours)*3600) + (parseInt(remainingMinutes)*60) + parseInt(remainingSeconds)
    let initialTotalSeconds = (parseInt(initialHours)*3600) + (parseInt(initialMinutes)*60) + parseInt(initialSeconds)

    let passedTotalSeconds: number | string = initialTotalSeconds - remainingTotalSeconds
    let passedHours: number | string = Math.floor(passedTotalSeconds / 3600)
    let passedMinutes: number | string = Math.floor((passedTotalSeconds - (passedHours * 3600)) / 60)
    let passedSeconds: number | string = passedTotalSeconds - (passedHours * 3600) - (passedMinutes * 60)
    if (passedHours   < 10) { passedHours   = "0" + passedHours }
    if (passedMinutes < 10) { passedMinutes = "0" + passedMinutes }
    if (passedSeconds < 10) { passedSeconds = "0" + passedSeconds }

    return passedHours + ':' + passedMinutes + ':' + passedSeconds

}

export function prettyPrintPassedTime(passedTime: any) {

    let hours = parseInt(passedTime.slice(0,2))
    let minutes = parseInt(passedTime.slice(3,5))
    let seconds = parseInt(passedTime.slice(6,8))

    return hours + ' hrs ' + minutes + ' mins ' + seconds + ' secs '
}

export function getSubarray<Q>(initialArray: Array<Q>, start: number, end: number) {
    return initialArray.slice(start, end + 1)
}


export const clearQusetionsSessionStorage = () => {
    // sessionStorage.removeItem("examAll");
    // sessionStorage.removeItem("quizAll");
    // sessionStorage.removeItem("quizCategories");
    // sessionStorage.removeItem("examCategories");
    return null
}

// Some questions are required to be asked after other questions - need to maintain that order
// We also want to randomize the questions asked each time.
export function randomizeQuestions<Q extends Pick<QuizQuestion, 'showAfter' | 'userDefinedId'>>(questions: Q[]): Q[] {
    const orderIndependentQuestions = questions.filter(q => !q.showAfter || q.showAfter.toLowerCase() === 'not set');
    const orderDependentQuestions = questions.filter(q => q.showAfter && q.showAfter.toLowerCase() !== 'not set');
    const orderDependentQuestionGroups: Q[][] = [];
    // key is userDefinedQuestionId, value is {question, groupIndex}
    const questionToGroupMap: {[userDefinedId: string]: {question: Q, groupIndex: number}} = questions.reduce((m, q) => {
        return {...m, [q.userDefinedId || '']: {question: q, groupIndex: -1}}
    }, {});

    orderDependentQuestions.forEach(q => {
        const previousQuestionWithGroupIndex = questionToGroupMap[q.showAfter || ''];
        const currentQuestionWithGroupIndex = questionToGroupMap[q.userDefinedId || ''];

        if (!previousQuestionWithGroupIndex) {
            if (currentQuestionWithGroupIndex.groupIndex < 0) {
                orderDependentQuestionGroups.push([currentQuestionWithGroupIndex.question]);
            }
            return;
        }

        if (previousQuestionWithGroupIndex.groupIndex >= 0) {
            // Ensure the current question is not already in the list
            if (orderDependentQuestionGroups[previousQuestionWithGroupIndex.groupIndex].indexOf(currentQuestionWithGroupIndex.question) >= 0) {
                return;
            }

            // The previous question already has a group, so we need to insert the current question after it.
            const index = orderDependentQuestionGroups[previousQuestionWithGroupIndex.groupIndex].indexOf(previousQuestionWithGroupIndex.question);
            orderDependentQuestionGroups[previousQuestionWithGroupIndex.groupIndex].splice(index+1, 0, q);
            questionToGroupMap[q.userDefinedId || ''].groupIndex = previousQuestionWithGroupIndex.groupIndex;
        } else if (currentQuestionWithGroupIndex.groupIndex >= 0) {
            // Ensure the current question is not already in the list
            if (orderDependentQuestionGroups[currentQuestionWithGroupIndex.groupIndex].indexOf(previousQuestionWithGroupIndex.question) >= 0) {
                return;
            }

            // The current question already has a group, so we need to insert the previous question before it.
            const index = orderDependentQuestionGroups[currentQuestionWithGroupIndex.groupIndex].indexOf(currentQuestionWithGroupIndex.question);
            orderDependentQuestionGroups[currentQuestionWithGroupIndex.groupIndex].splice(index, 0, previousQuestionWithGroupIndex.question);
            questionToGroupMap[previousQuestionWithGroupIndex.question.userDefinedId || ''].groupIndex = currentQuestionWithGroupIndex.groupIndex;
        } else {
            // Neither question has a group yet
            orderDependentQuestionGroups.push([previousQuestionWithGroupIndex.question, currentQuestionWithGroupIndex.question]);
            questionToGroupMap[previousQuestionWithGroupIndex.question.userDefinedId || ''].groupIndex = orderDependentQuestionGroups.length-1;
            questionToGroupMap[currentQuestionWithGroupIndex.question.userDefinedId || ''].groupIndex = orderDependentQuestionGroups.length-1;
        }
    });

    // Randomize the order, but keep the dependent questions ordered correctly
    const arrayedIndependentQuestions = orderIndependentQuestions
        .filter(q => questionToGroupMap[q.userDefinedId || ''].groupIndex < 0)
        .map(q => [q]);
    const randomlyOrderedGroups = randomizeArray([...arrayedIndependentQuestions, ...orderDependentQuestionGroups]);
    return uniqueArray(randomlyOrderedGroups.reduce((acc: Q[], curr: Q[]) => {
        return [...acc, ...curr];
    }, []));
}

export function randomizeArray<Q>(array: Array<Q>) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function uniqueArray<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

// Returns an empty string if 'null' or 'undefined' are the values of the string
export function stringFromPossibleNullString(s?: string | undefined | null): string {
    if (!s) {
        return '';
    }
    if (s.toLowerCase() === 'null' || s.toLowerCase() === 'undefined') {
        return '';
    }
    return s;
}

export function examTimerToDisplayString(et?: ExamTime): string {
    let timeDisplay = '';
    if (!et) {
        return '';
    }
    if (et.hours) {
        timeDisplay = `${et.hours} ${et.hours === 1 ? 'hour' : 'hours'}`;
    }
    if (et.minutes) {
        timeDisplay = `${timeDisplay} ${et.minutes} ${et.minutes === 1 ? 'minute' : 'minutes'}`;
    }
    if (et.seconds) {
        timeDisplay = `${timeDisplay} ${et.seconds} ${et.seconds === 1 ? 'second' : 'seconds'}`;
    }
    return timeDisplay.trim();
}

export function base64Encode(str: string): string {
    str = stringFromPossibleNullOrUndefinedString(str);
    if (!str) {
        return '';
    }
    try {
        return Buffer.from(str).toString("base64");
    } catch (e) {
        console.error(`failed to encode ${e}`);
        return str;
    }
}

export function base64Decode(encodedStr: string | undefined | null): string {
    encodedStr = stringFromPossibleNullOrUndefinedString(encodedStr);
    if (!encodedStr) {
        return '';
    }
    try {
        return atob(encodedStr);
    } catch (e) {
        console.error(`failed to decode ${e}`);
        return encodedStr;
    }
}

export function stringFromPossibleNullOrUndefinedString(str: string | undefined | null): string {
    if (!str || str === 'undefined' || str === 'null') {
        return '';
    }
    return str;
}

export function userIsAdmin(uid: string): boolean {
    return uid === 'V4sPgEbA0mM0IjwiZnhZQ2z2eMI2';
}
