import React, {useCallback, useMemo, useState} from "react";
import {useQuizQuestions} from "../firebase-listeners/quiz-questions";
import TopBar from "../components/TopBar";
import {History} from "history";
import {CardFlip} from "../components/CardFlip";
import {PreventCopyPaste} from "../components/PreventCopyPaste";
import {Button, makeStyles} from "@material-ui/core";

export type QuizProps = {
	location: {
		search: string;
	},
	match: {
		params: {
			category: string;
		}
	};
	history: History;
}

const useStyles = makeStyles({
	examQuestionNumberBox: {
		width: '125px',
		background: '#F4F6F8',
		border: '.8px solid #979797',
		textAlign: 'left',
		borderRadius: '7px',
	},
})

export const Quiz = (props: QuizProps) => {
	const classes = useStyles();
	const category = props.match.params.category?.toLowerCase() || '';
	const allQuizQuestions = useQuizQuestions()?.sort((a, b) => a.id.localeCompare(b.id));
	const quizQuestions = category ? (allQuizQuestions || [])
		.filter(q => q.category?.toLowerCase() === category.toLowerCase()) : allQuizQuestions;

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

	const [flipped, setFlipped] = useState(false);
	const currentQuestion = quizQuestions?.[currentQuestionIndex];
	const nextQuestion = useCallback((e: React.MouseEvent) => {
		setFlipped(false);
		setCurrentQuestionIndex(index => Math.min(index + 1, quizQuestions?.length - 1));
		e.preventDefault();
	}, [quizQuestions, setCurrentQuestionIndex]);
	const endQuiz = useCallback(() => {
		props.history.push('/student/home')
	}, [props.history]);
	const previousQuestion = useCallback((e: React.MouseEvent) => {
		setCurrentQuestionIndex(index => Math.max(0, index - 1));
		setFlipped(false);
		e.preventDefault();
	}, [setCurrentQuestionIndex]);

	const getEnunciationSections = useCallback(() => {
		// Returns a list of strings used to separate the text in a question
		return currentQuestion?.enunciation?.split('\n') || [''];
	}, [currentQuestion]);

	const TextContainer = (p: { children: React.ReactNode[] | React.ReactNode }) => (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontWeight: 'bold',
			fontSize: '2rem',
			lineHeight: '1.4',
			textAlign: 'center'
		}}>
			<PreventCopyPaste>{p.children}</PreventCopyPaste>
		</div>
	)

	const LoadingQuestions = useMemo(() => {
		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "75%"}}>
				<p>Loading questions...</p>
			</div>
		)
	}, []);

	const Card = (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		}}>
			<CardFlip
				flipped={flipped} styles={{minHeight: '480px', maxHeight: '600px', maxWidth: '960px', width: '100%', cursor: 'pointer'}}
				onClick={() => {
					setFlipped(flipped => !flipped)
				}}
			>
				<>
					<div style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
						{getEnunciationSections().map(enunciation => <TextContainer
							key={enunciation}>{enunciation}</TextContainer>)}
					</div>
				</>
				<>
					<div style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
						<TextContainer>{currentQuestion?.explanation || currentQuestion?.correctChoice}</TextContainer>
					</div>
				</>
			</CardFlip>
			<div style={{
				marginTop: "28px",
				display: 'flex',
				justifyContent: 'space-around',
				width: '500px'
			}}>
				{
					<img
						role={'button'}
						onClick={previousQuestion}
						src={require("../assets/images/left-arrow.png")}
						style={{
							height: "20px",
							float: "right",
							cursor: "pointer",
						}}
					/>
				}
				<div
					style={{marginBottom: '20px', position: 'relative', top: '-6px'}}
					className={`col-2 ${classes.examQuestionNumberBox}`}
				>
					<h4
						style={{fontSize: "1rem", fontWeight: "normal", marginBottom: 0, textAlign: 'center'}}
					>
						{currentQuestionIndex + 1 + "/" + quizQuestions?.length}
					</h4>
				</div>
				{currentQuestionIndex === quizQuestions?.length - 1 ? <Button onClick={endQuiz}>End quiz</Button> :
					<img
						role={'button'}
						onClick={nextQuestion}
						src={require("../assets/images/right-arrow.png")}
						style={{
							height: "20px",
							float: "right",
							cursor: "pointer",
						}}
					/>
				}
			</div>
		</div>
	);

	return <div className="container">
		<TopBar title={'Quiz'} history={props.history}/>
		<div className="mt-3 mb-5" style={{
			top: "20px",
		}}>
			{quizQuestions?.length > 0 ? Card : LoadingQuestions}
		</div>
	</div>
}
