import React from "react";
import {withFirebase} from "../../components/Firebase";

import "./AdminExamQuestions.css";
import AdminExamQuestionsTable from "./AdminExamQuestionsTable";
import {useExamQuestions} from "../../firebase-listeners/exam-questions";

export const AdminExamQuestions = () => {
	const questions = useExamQuestions();
	return (
		// @ts-ignore
		<div style={{ background: "white", display: "flex", justifyContent: "center" }}>
			<div
				onClick={() => {
					window.history.back();
				}}
				style={{
					color: "blue",
					cursor: "pointer",
					textDecoration: "underline",
					marginRight: "36px",
					display: "inline-block",
				}}
			>
				Go back
			</div>

			<AdminExamQuestionsTable questions={questions}/>
			<br/>
		</div>
	);
}

export default withFirebase(AdminExamQuestions);
