import {ExamQuestion} from './interface';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {firestore} from 'firebase/app';
import {useIsUserLoggedIn} from './auth';
import {base64Decode, base64Encode} from '../helpers/helper-functions';

const emptyArray: any[] = [];

const setQuestions = (newState: ExamQuestion[]) => {
	questions = newState;
	listeners.forEach((listener) => {
		listener(questions);
	});
};

function buildKey(): string {
	let domain = 'localhost';
	if (typeof window.location !== 'undefined' && window.location.hostname) {
		domain = window.location.hostname;
	}
	return `${domain}-reqmaster-exam-questions`;
}

function getInitialFromLocalStorage():ExamQuestion[] {
	const result = base64Decode(window.localStorage.getItem(buildKey()));
	if (!result) {
		return emptyArray;
	}
	const q = JSON.parse(result) as ExamQuestion[];
	return q;
}

// Globally store Exam Questions to avoid excessive firestore calls
let listeners: Dispatch<SetStateAction<ExamQuestion[]>>[] = [];
let questions: ExamQuestion[] =  getInitialFromLocalStorage();

let cancelSubscription: (() => unknown) | undefined = undefined;


function setInLocalStorage(questions: ExamQuestion[]): void {
	window.localStorage.setItem(buildKey(), base64Encode(JSON.stringify(questions)));
}

export function useExamQuestions(): ExamQuestion[] {
	const isLoggedIn = useIsUserLoggedIn();
	const newListener = useState(questions)[1];

	useEffect(() => {
		listeners.push(newListener);
	}, [newListener]);

	useEffect(() => {
		// Already listening
		if (isLoggedIn && cancelSubscription) {
			return;
		}
		// Logged out, and is not listening
		if (!isLoggedIn && !cancelSubscription) {
			return;
		}
		// Logged in, not listening yet
		if (isLoggedIn && !cancelSubscription) {
			// TODO: Remove - we use local storage when developing locally
			if (typeof window.location !== 'undefined' && window.location.hostname.indexOf('localhost') >= 0 && questions && questions.length > 0) {
				return;
			}
			cancelSubscription = firestore()
				.collection('Exam Questions')
				.onSnapshot({
					next: (snapshot: firestore.QuerySnapshot) => {
						const questions = removeDuplicatesByLatestUpdated(
							(snapshot.docs || []).map(d => ({...d.data(), docId: d.id}) as any as  ExamQuestion)
						);
						setInLocalStorage(questions);
						setQuestions(questions);
					},
					error: (error: firestore.FirestoreError) => {
						console.error(`got firestore error reading exam questions: ${error}`);
					}
				})
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			// only unsubscribe if we are logged out. Keeping the subscription alive does not have bad consequences
			if (!isLoggedIn && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
				listeners = [];
			}
		}
	}, [isLoggedIn, newListener]);
	return questions;
}

// A temporary patch due to some questions be duplicates.
function removeDuplicatesByLatestUpdated(questions: ExamQuestion[]): ExamQuestion[] {
	const ids = questions.map(q => q.id);
	const questionsWhereIdsDoNotMatchDocumentId = questions.filter(q => q.id !== q.docId);
	return questions.filter(q => questionsWhereIdsDoNotMatchDocumentId.indexOf(q) < 0);
}
