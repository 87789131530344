import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {firestore} from 'firebase/app';
import {useIsUserLoggedIn} from './auth';
import {Exam} from './interface';

// Globally store Exams to avoid excessive firestore calls
let listeners: Dispatch<SetStateAction<Exam[]>>[] = [];
let exams: Exam[] = [];
let cancelSubscription: (() => unknown) | undefined = undefined;


const setQuestions = (newState: Exam[]) => {
	exams = newState;
	listeners.forEach((listener) => {
		listener(exams);
	});
};

export function useExams(): Exam[] {
	const isLoggedIn = useIsUserLoggedIn();
	const newListener = useState(exams)[1];

	useEffect(() => {
		listeners.push(newListener);
	}, [newListener]);

	useEffect(() => {
		// Already listening
		if (isLoggedIn && cancelSubscription) {
			return;
		}
		// Logged out, and is not listening
		if (!isLoggedIn && !cancelSubscription) {
			return;
		}
		// Logged in, not listening yet
		if (isLoggedIn && !cancelSubscription) {
			cancelSubscription = firestore()
				.collection('Exam')
				.onSnapshot({
					next: (snapshot: firestore.QuerySnapshot) => {
						setQuestions((snapshot.docs || []).map(d => {
							const e = d.data() || {};
							if (e.examTime && Array.isArray(e.examTime)) {
								e.examTimeStructured = {
									hours: e.examTime[0] || 0,
									minutes: e.examTime[1] || 0,
									seconds: e.examTime[2] || 0,
									timerActive: e.examTime.length > 0,
								}
							} else {
								e.examTimeStructured = {
									hours: 0,
									minutes: 0,
									seconds: 0,
									timerActive: false,
								}
							}
							return ({...e, exam: d.id}) as Exam
						}));
					},
					error: (error: firestore.FirestoreError) => {
						console.error(`got firestore error reading exams: ${error}`);
					}
				})
		}
		return () => {
			listeners = listeners.filter(listener => listener !== newListener);
			// only unsubscribe if we are logged out. Keeping the subscription alive does not have bad consequences
			if (!isLoggedIn && cancelSubscription) {
				cancelSubscription();
				cancelSubscription = undefined;
			}
		}
	}, [isLoggedIn, newListener]);
	return exams;
}

export function useExamTypes(): string[] {
	const exams = useExams();
	const [examTypes, setExamTypes] = useState<string[]>(getExamTypesFromExams(exams))
	useEffect(() => setExamTypes(getExamTypesFromExams(exams)), [exams]);
	return examTypes;
}

function getExamTypesFromExams(exams: Exam[]): string[] {
	return (exams || []).map(exam => exam.exam);
}
