import React from "react";
import { Route } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import routes from "./routes";
import withTracker from "./withTracker";
import './App.scss';

import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard-styles/shards-dashboards.1.1.0.min.css";

const initialState = {
    counter: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INCREASE_COUNTER':
            return { counter: state.counter + 1 }
        case 'DECREASE_COUNTER':
            return { counter: state.counter - 1 }
    }
    return state
}

const store = createStore(reducer)


function App() {
    // localStorage.setItem('userLoggedIn','false');
    document.title="Reqmaster";
    return (
        <Provider store={store}>
                    {/* basename={process.env.REACT_APP_BASENAME || ""} */}
                    {/* {document.addEventListener('contextmenu', event => event.preventDefault())}  */}
                <div style={{ backgroundColor: 'white', width: window.innerWidth, height: window.innerHeight }}>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={withTracker(props => {
                                    return (
                                        <>
                                            <route.component {...props} />
                                        </>
                                    );
                                })}
                            />
                        );
                    })}
                </div>
        </Provider>
    )

}

export default App

