export interface ExamQuestion {
	id: string;
	// Most of the time docId IS id. However, some questions have a different id than their docId for some reason
	docId: string;
	userDefinedId?: string;
	category: string;
	exam?: string;
	choicesArray: string[];
	// The index into the choices array for the correct answer
	correctChoiceIndex: number;

	enunciation: string;
	explanation: string;
	questionType: string;
	picture: string;

	// the userDefinedId of another question this question needs to be shown after
	showAfter: string;

	// Not filled for all questions yet
	updatedISO?: string;
}

export interface QuizQuestion {
	id: string;
	category: string;
	choicesArray: string[];
	correctChoice: string;
	enunciation: string;
	picture?: string;
	explanation?: string;
	showAfter?: string;
	userDefinedId?: string;
}

// Represents as it is stored in the database
export interface QuizCategories {
	// @ts-ignore
	'Knowledge Areas': {
		[category: string]: ''
	};
	'Sequence': {
		'Knowledge Areas': string[];
		Techniques: string[];
	};
	// @ts-ignore
	Techniques: {
		[techniqueCategory: string]: '';
	};

	[topLevelCategory: string]: {
		[subCategory: string]: string[] | { [subCategoryName: string]: '' }
	}
}

export interface FirestoreUser {
	uid: string;
	answerValues: unknown;
	answeredQuestions: unknown;
	availableExam: string;
	email: string;
	examTime: { hours: number, minutes: number, seconds: number };
	firstname: string;
	flaggedQuestions: unknown;
	lastname: string;
	// formatted as month/day/year
	licenseExpirationDate: string;

	// the identifiers of the answered questions
	practiceAnsweredQuestions: string[];
	simulationAnsweredQuestions: string[];

	// Holds the current state for exams.
	examStats: ExamStatsMap;
}

export interface ExamStatsMap {
	// examKey is expected to be a deterministic key made from the exam type and the categories (in alphabetical order)
	// examKey should be all lowercase separated by hyphens
	// i.e., cbap-simulation   for a CBAP exam across all categories
	// cbap-practice-sequence-knowledge-areas-elicitation-and-collaboration-strategy-analysis for cbap practice exam under sequence category, knowledge areas, elicitation and strategy analysis
	[examKey: string]: ExamStats;
}

export interface ExamStats {
	// The current question the user is on.
	// Skipped questions can be computed by looking at what current question they are on, what the question order was, and what questions they have answers to
	currentQuestionId: string;
	currentQuestionOrder: string[];
	// A map of the question id to the question answer
	answers: {
		[questionId: string]: string;
	};
	markedQuestionIds: string[];
	examTime?: Omit<ExamTime, 'timerActive'>;
}

export function getDefaultExamStats(): ExamStats {
	return {
		currentQuestionId: '',
		currentQuestionOrder: [],
		// A map of the question id to the question answer
		answers: {},
		markedQuestionIds: [],
	};
}

export interface Exam {
	exam: string;
	// first is hours, second is minutes, third is seconds
	examTime: number[];
	examTimeStructured: ExamTime;
}

export interface ExamTime {
	hours: number;
	minutes: number;
	seconds: number;
	timerActive: boolean;
}
