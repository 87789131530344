import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import Firebase, {FirebaseContext} from './components/Firebase';
import 'bootstrap/dist/css/bootstrap.css';

// Routing on the server 

import {BrowserRouter} from "react-router-dom";

import App from "./App.js";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
	apiKey: "AIzaSyC03ZoIYqqt6-I8y7IvxfVho4VWKFzSZ2Y",
	authDomain: "reqmaster-bf3de.firebaseapp.com",
	databaseURL: "https://reqmaster-bf3de.firebaseio.com",
	projectId: "reqmaster-bf3de",
	storageBucket: "reqmaster-bf3de.appspot.com",
	messagingSenderId: "519752915517",
	appId: "1:519752915517:web:fbfeb467cc6c3d49bfe854",
	measurementId: "G-MG9KLF8XX3",
};

app.initializeApp(config);

ReactDOM.render(
	<BrowserRouter>
		<FirebaseContext.Provider value={new Firebase() as any}>
			<App/>
		</FirebaseContext.Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
