import React from "react";
import { FormTextarea } from "shards-react";
import {noCopyPasteProps} from "./PreventCopyPaste";

export const CorrectAnswerBox = (props) => {
    return (
        <div
            className="centered-div-2"
            style={{
                overflowY: "scroll",
                backgroundColor: "#c1e6e7",
                padding: "20px 25px",
                margin: "15px",
                borderRadius: "7px",
                fontSize: "18px",
            }}
        >
            {props.selectedAnswer && props.selectedAnswer === props.answer && (
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                    Hurray! You got it right :-)
                </p>
            )}
            {props.selectedAnswer && props.selectedAnswer !== props.answer && (
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                    That is not a correct answer :-(
                </p>
            )}
            {props.showYourAnswer && props.selectedAnswer && <p>
                <b>Your answer: </b>
                {props.selectedAnswer}
            </p>
            }
            <p {...noCopyPasteProps}>
                <b>Correct answer: </b>
                {props.answer}
            </p>
            {props.isFromQuiz ? null : (
                <React.Fragment>
                    <label>
                        <b>Explanation:</b>
                    </label>
                    <p
                        {...noCopyPasteProps}
                        style={{
                            ...noCopyPasteProps.style,
                            backgroundColor: "#c1e6e7",
                            border: "none",
                            minHeight: "90px",
                            fontSize: "18px",
                            color: "#606169",
                        }}
                        id="answerExplanation"
                    >
                        {props.explanation}
                    </p>
                </React.Fragment>
            )}
        </div>
    );
}

export default CorrectAnswerBox;
