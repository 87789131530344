import React, { Component } from "react";

import { checkUserLoggedStatus } from "../helpers/helper-functions";
import "bootstrap";
import { Link } from "react-router-dom";
import { withFirebase } from "../components/Firebase";
import CategoryChoiceBox from "../components/Selections/CategoryChoiceBox";
import { SolarSystemLoading } from "react-loadingg";

var questions = [];

class ReviewCategories extends Component {
  constructor(props) {
    super(props);
    checkUserLoggedStatus();
  }

  state = {
    categories: [],
    subCategories: [],
    isPageLoaded: false,
    categoryData: {},
  };

  fetchQuestionsFromDb = (category, answeredQuestionsIds) => {
    this.props.firebase
      .doGetQuizQuestionsByCategory(category)
      .then((res) => {
        var newArray = res.filter((item, index) => res.indexOf(item) === index); // remove duplicates
        var notAnsweredQuestions;
        if (answeredQuestionsIds) {
          notAnsweredQuestions = newArray.filter((v) => {
            var result = answeredQuestionsIds.find(
              (answeredId) => answeredId == v.id
            );
            if (result == undefined) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          notAnsweredQuestions = newArray;
        }
        questions = {
          ...questions,
          [category]: notAnsweredQuestions, //  notAnsweredQuestions
        };
      })
      .then((final) => {
        this.setState({ isPageLoadedQuestions: true });
      })
      .catch((error) => {
        console.log("Error" + error);
      });
  };

  componentDidMount() {
    questions = [];
    document.title = "Review Categories";
    this.getCategories();
  }

  getAnsweredQuestions = () => {
    this.props.firebase.getAnsweredQuestionsIds().then((v) => {
      return v;
    });
  };

  goBack = () => {
    if (this.state.subCategories.length !== 0) {
      this.setState({ subCategories: [] });
    } else {
      console.log("hell");
      window.location = "/student/home";
    }
  };

  getCategories = () => {
    this.props.firebase
      .getQuizCategories()
      .then((res) => {
        console.log("This is the response", res);
        this.setState({
          categories: ["Knowledge Areas", "Techniques"],
          categoryData: {
            "Knowledge Areas": [...res.knowledgeAreas],
            Techniques: [...res.techniques],
          },
        });
        return res;
      })
      .then(() => this.setState({ isPageLoaded: true }));
  };

  getSubCategories = (category) => {
    let answeredQuestionsIds = this.getAnsweredQuestions();
    var newArray = [...this.state.categoryData[category]];
    newArray.map((v) => {
      this.fetchQuestionsFromDb(v, answeredQuestionsIds);
    });

    this.setState({ subCategories: [...newArray], isPageLoaded: true });
  };

  fetchSubCategories = (category) => {
    this.setState({ isPageLoaded: false });
    this.getSubCategories(category);
  };

  setChecked = (category) => {
    this.setState({ checked: category });
  };
  clearChecks() {
    this.setState({ checked: false });
  }

  render() {
    var nextArrow = (
      <div className="row">
        <p style={{ color: "#192438", fontSize: "20px", marginRight: 20 }}>
          Next
        </p>
        <img
          src={require("../assets/images/right-arrow.png")}
          style={{ height: "20px", marginTop: 5 }}
        />
      </div>
    );

    let content;

    if (!this.state.isPageLoaded) {
      content = (
        <SolarSystemLoading
          style={{ position: "fixed", top: "50%", left: "50%" }}
        />
      );
    } else {
      content = (
        <div style={{ height: window.innerHeight, overflowY: "scroll" }}>
          <div className="container main-content-container px-4">
            <div className="row" md={2} style={{ justifyContent: "flex-end" }}>
              <div className="col" md={2} style={{ top: "20px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    marginTop: "3px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  fill="none"
                  stroke="gray"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-home"
                  onClick={() => {
                    window.location.href = "/student/home";
                  }}
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                <p
                  style={{
                    float: "right",
                    marginRight: "10px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.firebase
                      .doSignOut()
                      .then(() => {
                        sessionStorage.setItem("userLoggedIn", "false");
                      })
                      .then(() => {
                        window.location.href = "/student/signin";
                      });
                  }}
                >
                  Log out
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{ paddingTop: "5%", justifyContent: "center" }}
            >
              <p className="page-title" style={{ fontSize: "25px" }}>
                {this.state.subCategories.length > 0
                  ? "Choose a knowledge area..."
                  : "Choose a sub category..."}
              </p>
            </div>
            {this.state.subCategories.length > 0
              ? this.state.subCategories.map((v, i) => (
                  <div
                    key={i}
                    className="row"
                    style={{ justifyContent: "center" }}
                  >
                    <div
                      onClick={() => {
                        this.clearChecks();
                        this.setChecked(v);
                      }}
                    >
                      <CategoryChoiceBox
                        message={v}
                        checked={this.state.checked === v ? true : false}
                      />
                    </div>
                  </div>
                ))
              : this.state.categories.map((v, i) => (
                  <div
                    key={i}
                    className="row"
                    style={{ justifyContent: "center" }}
                  >
                    <div
                      onClick={() => {
                        this.fetchSubCategories(v);
                      }}
                    >
                      <CategoryChoiceBox
                        message={v}
                        checked={this.state.checked === v ? true : false}
                      />
                    </div>
                  </div>
                ))}
            <div
              className="row"
              style={{ paddingTop: "2%", justifyContent: "center" }}
            >
              <div className="col" lg={5}>
                <div className="row">
                  <div className="col">
                    <div onClick={this.goBack} className="row">
                      <img
                        src={require("../assets/images/left-arrow.png")}
                        style={{ height: "20px", marginTop: 5 }}
                      />
                      <p
                        style={{
                          color: "#192438",
                          fontSize: "20px",
                          marginLeft: 20,
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row" style={{ justifyContent: "flex-end" }}>
                      {this.state.checked == "Techniques" ? (
                        <Link
                          to={{
                            pathname: "/student/subcategories",
                            state: {
                              questions: questions,
                              category: this.state.checked,
                            },
                          }}
                        >
                          {nextArrow}
                        </Link>
                      ) : !this.state.checked ? (
                        <div className="row">
                          <p
                            style={{
                              color: "#192438",
                              fontSize: "20px",
                              marginRight: 20,
                            }}
                          >
                            Next
                          </p>
                          <img
                            src={require("../assets/images/right-arrow.png")}
                            style={{ height: "20px", marginTop: 5 }}
                          />
                        </div>
                      ) : questions[this.state.checked].length !== 0 ? (
                        <Link
                          to={{
                            pathname: `/student/quiz/${this.state.checked}`,
                          }}
                        >
                          {/*paramsForNextView[0] */}
                          {nextArrow}
                        </Link>
                      ) : (
                        <p>No Quesions in this Category</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{content}</>;
  }
}

export default withFirebase(ReviewCategories);
