import React, {useEffect, useMemo, useState} from 'react';
import ShowImage from '../components/ShowImage';
import QuestionsNavigation from '../components/QuestionsNavigation'
import CorrectAnswerBox from "../components/CorrectAnswerBox";
import {ExamQuestion, QuizQuestion} from '../firebase-listeners/interface';

export type Props = {
    answers: {[questionId: string]: string};
    questions: ExamQuestion[];
}

export const ShowAnsweredQuestions = (props: Props) => {
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<ExamQuestion>(props?.questions?.[currentQuestionNumber]);
    const currentQuestionId = currentQuestion?.id;
    const enunciation = currentQuestion?.enunciation || '';
    const explanation = currentQuestion?.explanation || '';

    useEffect(() => setCurrentQuestion(props?.questions?.[currentQuestionNumber]), [currentQuestionNumber, props]);

    return useMemo(() => (
        <div>
            {/* {document.addEventListener('contextmenu', event => event.preventDefault())} */}
            <div>
                <div style={{ border: "1px solid silver", marginTop: "2vh", borderRadius: "10px" }} className="question-card row pb-5">
                    <div style={{ border: "1px solid black", borderRadius: "10px", backgroundColor: "rgb(221,221,221)", height: "max-content" }} className="col-2 mt-4 ml-4 mb-4">
                        <h4 className="mt-2" style={{ fontWeight: "normal" }}>
                            {currentQuestionNumber + 1 + "/" + props?.questions?.length}
                        </h4>
                        <p></p>
                    </div>
                    <div className="col mt-4">
                        <p>{enunciation.replace(/%missing_word%/g, "_________")}</p>
                        <ShowImage src={currentQuestion?.picture} />
                        <CorrectAnswerBox showYourAnswer={true} selectedAnswer={props?.answers?.[currentQuestion.id]} answer={currentQuestion?.choicesArray?.[currentQuestion?.correctChoiceIndex]} explanation={explanation} />
                        <QuestionsNavigation currentQuestionId={currentQuestionId} setCurrentQuestion={(questionNumber) => setCurrentQuestionNumber(questionNumber)} currentQuestionNumber={currentQuestionNumber} totalQuestions={props?.questions?.length || 0} />
                    </div>
                </div>
            </div>
        </div>
    ), [currentQuestion, currentQuestionId, currentQuestionNumber, enunciation, explanation, props]);
}

export default ShowAnsweredQuestions;
