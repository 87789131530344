import React, {useEffect, useMemo, useState} from "react";
// @ts-ignore
import {Container, Row, Col, FormSelect, FormInput, Button, FormTextarea,} from "shards-react";
// @ts-ignore
import {withRouter} from "react-router-dom";
import {withFirebase} from "../../components/Firebase";
import {useExamQuestions} from '../../firebase-listeners/exam-questions';
import {ExamQuestion} from '../../firebase-listeners/interface';
import AdminQuestionForm from '../../components/AdminQuestionForm';
// @ts-ignore

export type Props = {
	match: {
		params: {
			questionId: string
		}
	}
}

export const EditExamQuestion = (props: Props) => {
	const questionId = props.match.params.questionId;
	const questions = useExamQuestions();
	const [question, setQuestion] = useState<ExamQuestion | undefined>((questions || []).find(q => q.id === questionId));
	useEffect(() => setQuestion((questions || []).find(q => q.id === questionId)), [questionId, questions]);
	return useMemo(() => (
		<AdminQuestionForm question={question}/>
	), [question]);
}

export default withRouter(withFirebase(EditExamQuestion));
