import React from 'react';
import {
    Container, Row, Col, FormSelect, FormInput, Button
} from "shards-react";
import {withFirebase} from "../../components/Firebase";
import {checkUserLoggedStatus} from "../../helpers/helper-functions";

var subCategories = ["Techniques 1-5", "Techniques 6-10", "Techniques 11-15", "Techniques 16-20", "Techniques 21-25", "Techniques 26-30", "Techniques 31-35", "Techniques 36-40", "Techniques 41-45", "Techniques 46-50"]


const questionCategoryArray = ["Competencies", "Concepts", "Requirements Analysis and Design Definition",
    "Requirements Elicitation and Collaboration", "Requirements Lifecycle Management", "Requirements Planning and Monitoring",
    "Solution Evaluation", "Strategy Analysis", "Techniques", ...subCategories]

export class AdminQuizQuestions extends React.Component {

    postQuestionsOnDb(category, data) {
        this.props.firebase
            .doPostQuizQuestionsOnCategory(category, data)
            .then(res => {  })
            .then(res => { window.location.href = '/student/admin-quiz-questions' })
            .catch(error => {
                console.log("Error" + error)
            });
    }

    constructor(props) {
        super(props);

        checkUserLoggedStatus()
    }

    render() {

        return (
            <div style={{ height: window.innerHeight, overflowY: 'scroll' }}>
                <Container className="main-content-container px-4">
                    <Col style={{justifyContent: 'center'}}>
                        <Row style={{justifyContent: 'center'}}>
                            <Col md={10} style={{justifyContent: 'center', marginTop: window.innerHeight/4}}>
                                <Row>
                                    <Col md={3}>
                                        <label htmlFor="feInputName">Question Category</label>
                                        <FormSelect id="category">
                                            <option>Choice...</option>
                                            {questionCategoryArray.map((type, index) =>
                                                <option key={index}>{type}</option>
                                            )}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'center'}}>
                            <Col md={10} style={{justifyContent: 'center', marginTop: '30px'}}>
                                <Row>
                                    <Col md={6}>
                                        <label htmlFor="feInputName">Question Enunciation</label>
                                        <FormInput id="enunciation" type="enunciation" placeholder='eq: This is a placeholder sentence.'/>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '30px'}}>
                                    <Col md={3}>
                                        <label htmlFor="feInputName">Choices Array</label>
                                        <FormInput id="choices" type="choices" placeholder='eq: True && False'/>
                                    </Col>
                                    <Col md={3}>
                                        <label htmlFor="feInputName">Correct Answer</label>
                                        <FormInput id="correctChoice" type="correctChoice" placeholder='eq: True'/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'center', marginTop: '60px'}}>
                            <Col md={10}>
                                <Button size="md" style={{width: '200px'}} onClick={() => {
                                    let questionCategory = document.getElementById('category').value
                                    let choices = document.getElementById('choices').value
                                    let correctChoice = document.getElementById('correctChoice').value
                                    let enunciation = document.getElementById('enunciation').value

                                    let data = {
                                        'category': questionCategory,
                                        'choicesArray': choices.split(" && "),
                                        'correctChoice': correctChoice,
                                        'enunciation': enunciation
                                    }

                                    this.postQuestionsOnDb(questionCategory, data)
                                }}>
                                    Post Question
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>

        );
    }
}

export default withFirebase(AdminQuizQuestions);
