import {ExamQuestion} from '../firebase-listeners/interface';


import React, {useCallback, useEffect, useMemo, useState} from "react";
// @ts-ignore
import {Container, Row, Col, FormSelect, FormInput, Button, FormTextarea,} from "shards-react";
// @ts-ignore
import Select from "react-select";

import imageCompression from "browser-image-compression";
import {setExamQuestion} from '../actions/firestore';
import {v1 as uuidv1} from 'uuid';
import {ChoicesArray} from './ChoicesArray';
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {useExams, useExamTypes} from '../firebase-listeners/exams';
import {useSequenceCategories} from '../firebase-listeners/sequence-categories';

const toBase64 = (file: any): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve((reader.result || '').toString());
		reader.onerror = (error) => reject(error);
	});

const changePictureText = 'Change Current Picture';
const addPictureText = 'Select Picture +';
const choiceText = 'Choice...';

export type Props = {
	question?: ExamQuestion;
	restartStateOnSave?: boolean;
}

function getDefaultQuestion(): ExamQuestion {
	return {
		category: '',
		choicesArray: [],
		correctChoiceIndex: 0,
		enunciation: '',
		explanation: '',
		id: '',
		docId: '',
		picture: '',
		questionType: '',
		showAfter: ''
	};
}

const showAfterNoneOption = Object.freeze({label: 'None', value: ''});
const defaultQuestionIds: {label: string, value: string}[] = [showAfterNoneOption];
const emptyArray: any[] = [];
export const AdminQuestionForm = (props: Props) => {
	const examTypes = useExamTypes();
	const questionCategoryArray = useSequenceCategories();
	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const [successTimeout, setSuccessTimeout] = useState<any>(undefined);
	const [question, setQuestion] = useState<ExamQuestion>(props.question || getDefaultQuestion());
	const [showAfterUserIds, setShowAfterUserIds] = useState<{ label: string, value: string }[]>(defaultQuestionIds);
	const [pictureName, setPictureName] = useState<string>(question?.picture && question?.picture !== 'NAAN' ? changePictureText : addPictureText);
	const [base64Picture, setBase64Picture] = useState<string>(question?.picture || '');
	const [showAfter, setShowAfter] = useState<string>(question?.showAfter || '');
	const [choices, setChoices] = useState<string[]>(question?.choicesArray || emptyArray);
	const [correctChoiceIndex, setCorrectChoiceIndex] = useState<number>(question?.correctChoiceIndex || 0);
	const questions = useExamQuestions();

	// Cleanup success timeout if we navigate away from page
	useEffect(() => {
		return () => successTimeout ? clearTimeout(successTimeout) : undefined;
	}, [successTimeout]);

	useEffect(() => {
		const userDefinedQuestionIds: string[] = (questions || []).map(q => q.userDefinedId).filter(Boolean) as string[];
		setShowAfterUserIds([showAfterNoneOption, ...userDefinedQuestionIds.map(id => ({label: id, value: id}))]);
	}, [questions]);

	useEffect(() => {
		setChoices(question.choicesArray || []);
		setPictureName(question.picture ? changePictureText : addPictureText);
		setShowAfter(question.showAfter || '');
		setFormValues(question);
	}, [question]);

	const handleImageUpload = useCallback(async (event) => {
		const imageFile = event.target.files[0];

		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};
		try {
			const compressedFile = await imageCompression(imageFile, options);
			let encodedCompressedImageFile = await toBase64(compressedFile);
			await setBase64Picture(encodedCompressedImageFile);
		} catch (error) {
			alert("Oops, an error occurred: " + error.message);
		}
	}, []);

	const postQuestionOnDb = useCallback(async (category, data) => {
		const id = question.id || uuidv1();
		data = {
			...data,
			id: id,
		};

		return setExamQuestion({id, data, merge: true})
			.then(() => {
				setShowSuccess(true);
				setSuccessTimeout(setTimeout(() => setShowSuccess(false), 3000))
				if (props.restartStateOnSave) {
					setQuestion(getDefaultQuestion());
				} else {
					window.history.back();
				}
			})
			.catch((error) => {
				console.log("Error" + error);
			});
	}, [props, question.id]);


	return useMemo(() => (
		<div style={{height: window.innerHeight, overflowY: "scroll"}}>
			<Container className="main-content-container px-4">
				<div
					onClick={() => window.history.back()}
					style={{
						color: "blue",
						cursor: "pointer",
						textDecoration: "underline",
					}}
				>
					Go back
				</div>
				<Col style={{justifyContent: "center"}}>
					<Row style={{justifyContent: "center"}}>
						<Col
							md={10}
							style={{
								justifyContent: "center",
								marginTop: window.innerHeight / 10,
							}}
						>
							<Row>
								<Col md={3}>
									<label htmlFor="feInputName">Question Category</label>
									<FormSelect id="category">
										<option>Choice...</option>
										{questionCategoryArray.map((type, index) => (
											<option key={index}>{type}</option>
										))}
									</FormSelect>
								</Col>
								<Col md={3}>
									<label htmlFor="feInputName">Exam Type</label>
									<FormSelect id="exam_type">
										<option>Choice...</option>
										{examTypes.map((type, index) => (
											<option key={index}>{type}</option>
										))}
									</FormSelect>
								</Col>
								<Col md={3}>
									<label htmlFor="feInputName">Question Type</label>
									<FormSelect id="question_type">
										<option>Choice...</option>
										<option key={1}>Practice</option>
										<option key={2}>Simulation</option>
									</FormSelect>
								</Col>
								<Col md={3}>
									<label htmlFor="feInputName">Id:</label>
									<FormInput
										id="user-defined-id"
										type="user-defined-id"
										placeholder="eq: True"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{justifyContent: "center"}}>
						<Col
							md={10}
							style={{justifyContent: "center", marginTop: "30px"}}
						>
							<Row>
								<Col md={4}>
									<label htmlFor="feInputName">Show After</label>
									<Select
										className="font-weight-light"
										options={showAfterUserIds}
										value={typeof showAfter === 'string' ? {label: showAfter, value: showAfter} : showAfter}
										onChange={(opt: { label: string, value: string }) => {
											setShowAfter(opt.value)
										}}
									/>
								</Col>

								<Col md={5}>
									<label
										htmlFor="icon-button-file"
										id="imageFile"
										style={{
											marginTop: "33px",
											marginLeft: "15px",
											width: "200px",
											height: "30px",
											borderRadius: "10px",
											backgroundColor:
												pictureName === addPictureText
													? "#5c646c"
													: "green",
											cursor: "pointer",
											color: "white",
											textAlign: "center",
											fontSize: "13px",
											paddingTop: "5px",
										}}
									>
										{pictureName}
									</label>
									<input
										accept="image/*"
										id="icon-button-file"
										type="file"
										style={{display: "none"}}
										onChange={(event) => handleImageUpload(event)}
									/>
									{base64Picture && base64Picture !== "NAAN" ? (
										<p
											style={{
												fontSize: "13px",
												paddingLeft: "20px",
												paddingTop: "5px",
												textAlign: "center",
											}}
										>
											Image added!
										</p>
									) : (
										<></>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{justifyContent: "center"}}>
						<Col
							md={10}
							style={{justifyContent: "center", marginTop: "30px"}}
						>
							<Row>
								<Col md={6}>
									<label htmlFor="feInputName">Question Enunciation</label>
									<FormTextarea
										id="enunciation"
										type="enunciation"
										rows={5}
										placeholder="eq: This is a placeholder sentence."
									/>
								</Col>
								<Col md={6}>
									<label htmlFor="feInputName">Choices Array</label>
									<ChoicesArray choices={choices} setChoices={setChoices} setCorrectChoiceIndex={setCorrectChoiceIndex} correctChoiceIndex={correctChoiceIndex}/>
								</Col>
							</Row>

							<Row style={{marginTop: "30px"}}>
								<Col md={6}>
									<label htmlFor="feInputName">
										Correct Answer Explanation
									</label>
									<FormTextarea
										id="explanation"
										type="explanation"
										rows={5}
										placeholder="eq: This is another placeholder sentence."
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row
						style={{
							justifyContent: "center",
							marginTop: "20px",
							marginBottom: "20px",
						}}
					>
						<Col md={10}>
							<Button
								size="md"
								style={{width: "200px"}}
								onClick={async () => {
									const getValue = (id: string): string => {
										// @ts-ignore
										return document.getElementById(id).value || '';
									}

									let examType = getValue("exam_type");
									let questionType = getValue("question_type");
									let questionCategory = getValue("category");
									let enunciation = getValue("enunciation");
									let explanation = getValue("explanation");
									let userDefinedId = getValue("user-defined-id");
									let pictureProps;
									if (!question.picture) {
										pictureProps = "NAAN";
									} else {
										pictureProps = question.picture;
									}
									let picture = base64Picture !== "NAAN" ? base64Picture : pictureProps;

									let showAfterToSave = showAfter;
									if (showAfterIsObject(showAfter)) {
										showAfterToSave = showAfter.value;
									}

									let data: Partial<ExamQuestion> = {
										category: filterFormValue(questionCategory),
										choicesArray: (choices || []).map(c => filterFormValue(c)),
										correctChoiceIndex: correctChoiceIndex,
										showAfter: filterFormValue(showAfterToSave),
										enunciation: filterFormValue(enunciation),
										explanation: filterFormValue(explanation),
										picture: filterFormValue(picture),
										exam: filterFormValue(examType),
										questionType: filterFormValue(questionType),
										userDefinedId: filterFormValue(userDefinedId),
									};

									await postQuestionOnDb(questionCategory, data);
								}}
							>
								Post Question
							</Button>
							{showSuccess ? <div><p style={{ color: "green", display: "inline" }}>
								Question added successfully
							</p></div> : <></>}

						</Col>
					</Row>
				</Col>
			</Container>
		</div>
	), [questionCategoryArray, examTypes, showAfterUserIds, showAfter, pictureName, base64Picture, choices, showSuccess, handleImageUpload, question.picture, correctChoiceIndex, postQuestionOnDb]);
}

function showAfterIsObject(showAfter: string | { label: string, value: string }): showAfter is { label: string, value: string } {
	return typeof showAfter !== 'string' && Boolean(showAfter?.value);
}

function filterFormValue(s: string): string {
	if ([choiceText, addPictureText, changePictureText].indexOf(s) >= 0) {
		return '';
	}
	return s;
}

function setFormValues(question?: ExamQuestion) {
	const setValue = (id: string, value?: string) => {
		// @ts-ignore
		document.getElementById(id).value = value || '';
	}
	setValue("exam_type", question?.exam || '');
	setValue("question_type", question?.questionType);
	setValue("category", question?.category);
	setValue("enunciation", question?.enunciation);
	setValue("explanation", question?.explanation);
	setValue("user-defined-id", question?.userDefinedId || '');
}

export default AdminQuestionForm;
