import React, {useMemo, useState} from "react";
// @ts-ignore
import {Container, Row, Col, Button} from "shards-react";
// @ts-ignore
import {SolarSystemLoading} from "react-loadingg";

// @ts-ignore
import Redirect from "react-router-dom/Redirect";
import {Link} from 'react-router-dom';
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {useCurrentFirestoreUser} from '../firebase-listeners/firestore-user';
import {History} from 'history';
import {doSignOut} from '../actions/auth';
import {useExams} from '../firebase-listeners/exams';
import {examTimerToDisplayString} from '../helpers/helper-functions';

export type Props = {
	history: History;
}

export const SimulationQuestionsDetails = (props: Props) => {
	const [startExamClicked, setStartExamClicked] = useState<boolean>(false);
	const questions = useExamQuestions();
	const exams = useExams();
	const user = useCurrentFirestoreUser();
	const loaded = questions && questions.length > 0;
	const studentExam = (exams || []).find(e => e.exam === user?.availableExam);
	const timeDisplay = examTimerToDisplayString(studentExam?.examTimeStructured);
	const StartSimulationExam = useMemo(() => (
		<Redirect
			to={{
				pathname: "/student/show-simulation-questions/" + user?.availableExam,
				state: {
					response: questions,
					exam: user.availableExam,
				},
			}}
		/>
	), [questions, user]);

	const Loading = useMemo(() => (
		<SolarSystemLoading
			style={{position: "fixed", top: "50%", left: "50%"}}
		/>
	), []);

	const PageContent = useMemo(() => (
		<div
			className="centered-div-2"
			style={{height: window.innerHeight, overflowY: "scroll"}}
		>
			<Container fluid className="main-content-container px-4">
				<Row md={2} style={{justifyContent: "flex-end"}}>
					<Col md={2} style={{top: "20px"}}>
						<Link to={{pathname: "/student/home"}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								style={{
									marginTop: "3px",
									marginLeft: "20px",
									cursor: "pointer",
								}}
								fill="none"
								stroke="gray"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-home"
							>
								<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
								<polyline points="9 22 9 12 15 12 15 22"></polyline>
							</svg>
						</Link>
						<p
							style={{
								float: "right",
								marginRight: "10px",
								fontSize: "20px",
								cursor: "pointer",
							}}
							onClick={() => doSignOut().then(() => props.history.push("/student/signin"))}
						>
							Log out
						</p>
					</Col>
				</Row>
				<Col style={{justifyContent: "center"}}>
					<Row
						style={{
							justifyContent: "center",
							marginTop: window.innerHeight / 4,
						}}
					>
						<h1
							className="page-title"
							style={{textAlign: "center", lineHeight: "1.4"}}
						>
							Make sure you have what you need to be online for {timeDisplay}!
						</h1>
					</Row>
					<Row
						style={{
							justifyContent: "center",
							marginTop: window.innerHeight / 15,
						}}
					>
						<Button
							theme="primary"
							style={{fontSize: "15px"}}
							onClick={() => setStartExamClicked(true)}
						>
							Start exam simulation
						</Button>
					</Row>
					<Row
						style={{
							justifyContent: "center",
							marginTop: window.innerHeight / 7,
						}}
					>
						<Link to={{pathname: "/student/practice"}}>
							<Row>
								<img
									src={require("../assets/images/left-arrow.png")}
									style={{height: "20px", marginTop: 5}}
								/>
								<p
									style={{
										color: "#192438",
										fontSize: "20px",
										marginLeft: 20,
									}}
								>
									Back
								</p>
							</Row>
						</Link>
					</Row>
				</Col>
			</Container>
		</div>
	), [props.history, timeDisplay]);

	if (!loaded) {
		return Loading;
	}
	if (startExamClicked) {
		return StartSimulationExam;
	}
	return PageContent;
}

export default SimulationQuestionsDetails;

