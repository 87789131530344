import React, {useEffect, useMemo, useState} from "react";
import {useExamQuestions} from '../firebase-listeners/exam-questions';
import {ExamQuestion, ExamStats, getDefaultExamStats} from '../firebase-listeners/interface';
import {Exam} from '../components/Exam';
import {useCurrentUserId} from '../firebase-listeners/auth';
import {useExams} from '../firebase-listeners/exams';
import ReportRedirect from '../components/ReportRedirect';
import {History} from 'history';
import {stringFromPossibleNullString} from '../helpers/helper-functions';

export type Props = {
  location: {
    search: string;
    state: {
      exam: 'CBAP' | 'CCBA' | 'ECBA',
      examTime: any,
    },
  },
  match: {
    params: {
      exam: 'CBAP' | 'CCBA' | 'ECBA',
    }
  };
  history: History;
}

export const ShowSimulationQuestions = (props: Props) => {
  document.title = "Exam Simulation";
  const userId = useCurrentUserId();
  const queryParams = new URLSearchParams(props.location.search);
  const exam = props.match.params.exam;
  const restartExamUrl = `/student/show-simulation-questions/${exam}`;
  const category = stringFromPossibleNullString(queryParams.get('category')) || 'All';
  const allQuestions = useExamQuestions();
  const exams = useExams();
  const examTime = (exams || []).find(e => e.exam === exam)?.examTimeStructured;
  const [examStats, setExamStats] = useState<ExamStats>(getDefaultExamStats());
  const [showReport, setShowReport] = useState<boolean>(false);
  const [examKey, setExamKey] = useState<string>('')
  const [filteredQuestions, setFilteredQuestions] = useState<ExamQuestion[]>((allQuestions || []).filter(q => !category || q.category === category));
  useEffect(() => {
    const filteredQuestions = (allQuestions || [])
        .filter(q => q?.questionType?.toLowerCase() === 'simulation')
        .filter(q => q.exam === exam);
    if (!category || category.toLowerCase() === 'all') {
      setFilteredQuestions(filteredQuestions);
    } else {
      setFilteredQuestions((filteredQuestions || []).filter(q => q.category === category))
    }
  }, [allQuestions, category, exam]);

  const Report = useMemo(() => (
      <ReportRedirect topBarTitle={`Simulation Exam`} exam={exam} examKey={examKey} examStats={examStats} questions={filteredQuestions} uid={userId} category={category} restartExamUrl={restartExamUrl}/>
    ), [category, exam, examKey, examStats, filteredQuestions, restartExamUrl, userId]);

  return useMemo(() => showReport ? Report : (
      <Exam
          history={props.history}
          topBarTitle={`Simulation Exam`}
          questions={filteredQuestions}
          examType={'Simulation'}
          exam={exam}
          category={category || ''}
          setExamStats={setExamStats}
          timer={examTime}
          showAnswerCard={false}
          shouldShowReport={true}
          restartExamUrl={restartExamUrl}
          endButtonCTA={
            {
              title: 'End Exam and show the report',
              label: 'End Exam',
              onClick: (examKey: string) => {
                setExamKey(examKey);
                setShowReport(true);
              },
            }
          }
      />
  ), [Report, category, exam, examTime, filteredQuestions, props.history, restartExamUrl, showReport]);
}

export default ShowSimulationQuestions;
