import {clearQusetionsSessionStorage} from '../helpers/helper-functions';
import {FirestoreUser} from '../firebase-listeners/interface';
import {setFirestoreUser} from './firestore';
import {firestore, auth} from 'firebase/app';

export function doSignOut(): Promise<void> {
	return signOut();
}

export function signOut(): Promise<void> {
	return auth().signOut().then(() => {
		sessionStorage.setItem("userId", "");
		sessionStorage.setItem("userFirstName", "");
		sessionStorage.setItem("userLoggedIn", "false");
		clearQusetionsSessionStorage();
	});
}

export async function signInWithEmailAndPassword(req: {email: string, password: string}): Promise<FirestoreUser> {
	const userCredential = await auth().signInWithEmailAndPassword(req.email, req.password);
	const userId = userCredential?.user?.uid || '';
	sessionStorage.setItem("userLoggedIn", "true");
	sessionStorage.setItem("userId", userId);

	const userSnapshot = await firestore().collection('User').doc(userId).get();
	const user = {...(userSnapshot.data() || {}), uid: userId} as FirestoreUser;
	sessionStorage.setItem("userFirstName", user?.firstname);
	return user;
}

export async function createUserWithEmailAndPassword(req: {email: string, password: string, user: Partial<FirestoreUser>}): Promise<Partial<FirestoreUser>> {
	const userCredential = await auth().createUserWithEmailAndPassword(req.email, req.password);
	return setFirestoreUser(userCredential?.user?.uid || '', {
		...req.user,
		uid: userCredential?.user?.uid,
		email: req?.user?.email || userCredential?.user?.email || '',
	});
}

export async function getCurrentUserId(): Promise<string | undefined> {
	const uid = sessionStorage.getItem('userId') || auth().currentUser?.uid;
	if (uid) {
		return uid;
	}
	return new Promise((resolve) => {
		setTimeout(() => resolve(auth().currentUser?.uid), 100);
	});
}
